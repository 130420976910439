
.h4 {
    font-size: 34px;
    text-align: center;
}

*{margin: 0; padding: 0 } 
::selection {color: #fff;background: #4596c5;} 
ol,ul { padding-left: 0 } 
body,html { width: 100%; height: 100% } 
body {overflow-x: hidden;font-weight: 500;color: #676767;font-family: 'Roboto', sans-serif;} 
a { text-decoration: none } 
h1,h2,h3,h4,h5,h6 {font-family:'Poppins', sans-serif;color: #1b1b1b; } 
h3 {font-size: 22px;margin-top: 4px;margin-bottom: 0px;color: #061259;text-transform: uppercase;} 
p {padding: 0 0 10px 0;letter-spacing: 0.3px;color: #303131;font-size: 16px;font-weight: 500;text-align: justify;line-height: 29px;font-family: 'Roboto', sans-serif;margin-bottom: 0px;} 
p.feature__text {text-align: left;line-height: 28px;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;height: 62px;}
h1 { letter-spacing: 2px; margin-bottom: 20px; color: #130F40; font-size: 28px; font-weight: 600; text-transform: uppercase; line-height: 35px } 
li{letter-spacing: 0.3px;color: #303131;font-size: 16px;font-weight: 500;text-align: left;line-height: 36px;font-family: 'Roboto', sans-serif;list-style: none;}  
.multbg {    margin-bottom: -5px; background: linear-gradient( to right, #6f7280, #2a2c3c, #181827, #833ab4, #cd1616, #fcb045);background-size: 400% 400%;animation: multbg 10s infinite ease-in-out;} 
@keyframes multbg{
0% {background-position: 0 50%;} 
50% {background-position: 100% 50%;} 
100% {background-position: 0 50%;} 
}
.carousel-item:before {content: '';background: #00000021;width: 100%;height: 100%;position: absolute;}
#myBtn { display: none; position: fixed; bottom: 20px; right: 30px; z-index: 99; font-size: 18px; border: none; outline: none; background-color: red; color: white; cursor: pointer; padding: 10px 12px; border-radius: 4px; background: linear-gradient( to right,  #cd1616, #fcb045); } 
#myBtn:hover { background: linear-gradient( to right,  #fcb045, #cd1616); }
.red{color:#bd2050;}
.clr10{ height:20px;}
.lefttr  tr{
    text-align: left;
}
.topper-head{text-transform: capitalize !important;}
.btn-warning {
    color: #fff !important;
    background-color: #db4224;
    border-color: #db4224;
    margin-bottom: 15px;
}
.btn-warning:hover {background-color: #bd2050;border-color: #bd2050;color: white;} 
.btn-warning:focus { background-color: #eb721f; border-color: #d18e1a; } 
.card-body a { margin-right: 5px; }
.modal { background: #0000008a; z-index: 99999; }

.carousel-inner{position:relative;}
.online-reg {position:absolute;z-index: 9;width:max-content;display: block;left: 0;right: 0px;margin: auto;bottom: 70px;}
.online-reg ul{display:flex;}
.online-reg li{background: linear-gradient( to right, #cd1616, #fcb045);padding: 10px 20px;border-radius: 10px;margin-right:10px;}
.online-reg img{width: 40px;margin-right: 10px;}
.online-reg a{color: #fff;font-weight: bold;}
.online-reg li{ animation: blinker 1s linear infinite;} @keyframes blinker { 50% { background: linear-gradient( to right, #181827, #833ab4) } }

.highlights_sec p {margin: 0px;color: #ffffff;font-size: 16px;padding: 0px;padding-top: 6px;} 
.highlights_sec p a {padding: 10px;color: white;} 
.highlights_sec p a:hover {color: #fcdfa3;} 
.highlights_sec p i { color: #ffffff; font-size: 16px; } 
.highlights_sec h3 { margin-bottom: 0px; color: white; background: #a50d1e; text-align: center; position: relative; z-index: 99; width: 41px; float: right; margin-top: -35px; padding: 3px; } 
.highlights_sec h3 i { color: white; }
.highlights_sec {height:37px;}

.height300{ height:600px;}
header{background:#fff;border-radius: 5px;
    position: relative;
    z-index: 2;
    margin-right: 60px;
}

.logo {padding: 0px 0px;}
.logo img {
    padding-top: 10px;
    width: 90%;
}
.bd-hero-social-wrapper {display: flex;gap: 40px;position: absolute;left: 55px;transform-origin: 50% 0;top: 50%;transform: translate(-50%,-50%) rotate(-90deg);z-index: 2;} 
.bd-hero-social {position: relative;}
.bd-hero-social::before {content: "";position: absolute;height: 2px;width: 3px;background-color: #9c6263;right: -20px;top: 50%;transform: translateY(-50%);} 
.bd-hero-social a {text-shadow: 1px -1px 0px #000000;font-size: 16px;line-height: 32px;text-transform: capitalize;color: #ffffff;font-weight: bold;} 
.bd-hero-social a i {margin-inline-end: 10px;} 
.bd-hero-social:last-child::before {content: none} 
.bd-hero-social a:hover {color: #ffc742;}
.right-btn-link{float:right;margin-top: -58px;z-index:99;position: relative;}
.offcanvas-start{right:0!important;left:auto;z-index: 9999;}
.offcanvas-start{top:0;right:0;width:500px;border-right:1px solid rgba(0,0,0,.2);transform:translateY(-100%);height:100%;position:absolute;background:linear-gradient(100.88deg,#EBFFFE 0.6%,#FFF6EB 100%);backdrop-filter:blur(37px)}
.offcanvas-body{padding:10px 30px}
.home-con img{background:#bd2050;padding:7px;border-radius:4px}
.offcanvas__contact ul li:not(:last-child){margin-bottom:20px}
.offcanvas__contact-icon i{display:inline-block;width:45px;height:45px;line-height:38px;text-align:center;border-radius:50%;background:#bd205021;color:#bd2050;-webkit-transition:all .3s ease-out 0;-moz-transition:all .3s ease-out 0;-ms-transition:all .3s ease-out 0;-o-transition:all .3s ease-out 0;transition:all .3s ease-out 0}
.offcanvas__contact ul li a:hover i{background:#bd2050;color:#fff;border-color:#bd2050}
.offcanvas__contact-text a{font-size:16px;font-weight:400;color:#000}
.offcanvas__contact ul li a:hover{color:#bd2050}
.offcanvas__social{margin-top: 35px;}
.offcanvas__social ul{display:flex;gap:15px;border-top:1px solid #D5D5D5;padding:30px 0;justify-content: space-evenly;}
.offcanvas__social ul li:not(:last-child){margin-right:5px}
.offcanvas__social ul li{display:inline-block}
.offcanvas__social ul li a{display:inline-block;width:42px;height:42px;border:1px solid #D5D5D5;line-height:35px;text-align:center;color:#1b1b1b;border-radius:50px}
.offcanvas__social ul li a:hover{background:#bd2050;color:#fff!important;border-color:#e7779a}
.home-con h3 {font-size: 18px;color: black;font-weight: bold;line-height: 35px;margin-top: 35px;letter-spacing: 1px;gap: 15px;border-top: 1px solid #D5D5D5;padding: 30px 0;} 
.home-con p {margin-left: 5px;border: none;padding-bottom: 0;color: #000000;margin-bottom: 7px;} 
a.btn.btn-link {font-size: 29px;color: #e8094d;outline: none;}  
a.btn:focus{ outline:none;box-shadow: none;}

.menu-p-0{ padding:0px}
.header-menu.navigation{display:flex;margin-left: 0px;margin-right: 0px;}
#cssmenu,#cssmenu ul,#cssmenu ul li,#cssmenu ul li a,#cssmenu #head-mobile{border:0;list-style:none;line-height:1;display:block;position:relative;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;margin-bottom:0}
#cssmenu:after,#cssmenu > ul:after{content:".";display:block;clear:both;visibility:hidden;line-height:0;height:0}
#cssmenu #head-mobile{display:none}
#cssmenu{padding:10px 0px;}
#cssmenu > ul > li{float:left;margin-left:1px;position: relative;}
#cssmenu > ul > li:after {
    position: absolute;
    content: ' ';
    border-left: 1px solid #ccc;
    width: 100%;
    height: 55%;
    top: 10px;
    left: 0;
}
#cssmenu > ul > li:first-child:after {
    border: 0px;
}
.newnewsgr {
    position: relative;
    z-index: 9;
    padding-top: 40px;
}
.newnews:after {
    content: ' ';
    position: absolute;
    background: linear-gradient(321deg, #a51e1e00 60%, #f3c143 55%);
    width: 80%;
    height: 100%;
    left: 0;
    top: 0;
}
#cssmenu > ul > li > a{padding: 10px 13px;font-size:14px;letter-spacing:1px;text-decoration:none;color:#2b0e0c;text-transform:uppercase;/* font-weight:600; */     position: relative;
    z-index: 99;}
#cssmenu > ul > li:hover > a,#cssmenu ul li.active a{color:#bd2050}
#cssmenu > ul > li:hover,#cssmenu ul li.active:hover,#cssmenu ul li.active,#cssmenu ul li.has-sub.active:hover{-webkit-transition:background .3s ease;-ms-transition:background .3s ease;transition:background .3s ease}
#cssmenu > ul > li.has-sub > a{padding-right:10px}
#cssmenu > ul > li.has-sub > a:after{position:absolute;top:22px;right:11px;width:0px;height:0px;display:block;background:#2b0e0c;content:''}
#cssmenu > ul > li.has-sub > a:before{position:absolute;top:19px;right:14px;display:block;width:0px;height:0px;background:#2b0e0c;content:'';-webkit-transition:all .25s ease;-ms-transition:all .25s ease;transition:all .25s ease}
#cssmenu > ul > li.has-sub:hover > a:before{top:23px;height:0}
#cssmenu ul ul{position:absolute;left:-9999px}
#cssmenu ul ul li{height:0;-webkit-transition:all .25s ease;-ms-transition:all .25s ease;background:#fff;transition:all .25s ease;z-index:999;text-align:left}
#cssmenu li:hover > ul{left:auto;display:block;padding-top: 0px;}
#cssmenu li:hover > ul > li{height:auto;margin-left:0}
#cssmenu ul ul ul{margin-left:100%;top:0}
#cssmenu ul ul li a{padding:10px;width:300px;font-size: 14px;text-decoration:none;color:#000;letter-spacing:.5px;line-height:19px;border-bottom:1px solid #f2f2f2;text-transform:capitalize}
#cssmenu ul ul li:last-child > a,#cssmenu ul ul li.last-item > a{border-bottom:0}
#cssmenu ul ul li:hover > a,#cssmenu ul ul li a:hover{color:#fff;background:#bd2050}
#cssmenu ul ul li.has-sub > a:after{position:absolute;top:16px;right:11px;width:8px;height:2px;display:block;background:#7d7f85;content:''}
#cssmenu ul ul li.has-sub > a:before{position:absolute;top:13px;right:14px;display:block;width:2px;height:8px;background:#7d7f85;content:'';-webkit-transition:all .25s ease;-ms-transition:all .25s ease;transition:all .25s ease}
#cssmenu ul ul > li.has-sub:hover > a:before{top:17px;height:0}
#cssmenu ul ul li.has-sub:hover,#cssmenu ul li.has-sub ul li.has-sub ul li:hover{background:#363636}
#cssmenu ul ul ul li.active a{border-left:1px solid #333}
#cssmenu > ul > li.has-sub > ul > li.active > a,#cssmenu > ul ul > li.has-sub > ul > li.active> a{border-top:1px solid #333}


.myDiv table{margin:0px;}
.myDiv tr{background:#fff !important;}

.selected_year {
    display: block;
} 

.year_selction_sec {
    float: right;
    padding: 0px 15px;
}

.year_selction_sec select {
    margin: 0px;
}


div.nextPage, div.prevPage {
    padding: 5px;
    background: #fff;
}

#exampleModalToggle .carousel-item:before{
    content: '';background: #00000021;width: 100%;height: 100%;position: static;
}


#exampleModalToggle .carousel-control-prev-icon {
    filter: invert(1);
}

#exampleModalToggle .carousel-control-next-icon {
    filter: invert(1);
}

#exampleModalToggle .carousel-control-next,
#exampleModalToggle .carousel-control-prev {
    width: auto;
}

#exampleModalToggle .carousel-control-next {
    right: 0px;
}

#exampleModalToggle .modal-dialog {
    min-width: 800px;
}

.carousel-item .modal-header {
    background: linear-gradient( to right, #cd1616, #fcb045);
    padding: 10px;
}

#exampleModalToggle .modal-dialog img {
    width: 100%;
    object-fit: contain;
    background: #f1f1f1;
}

.carousel-item .modal-header h1 {
    padding-bottom: 0;
    text-align: center;
    font-size: 17px;
    color: #fff;
    overflow: hidden;
    text-transform: capitalize;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin-bottom: 0;
    line-height: 28px;
    font-weight: normal;
    margin: 0 auto;
    width: 100%;
}

.carousel-item .modal-dialog .modal-header .btn-close {
    color: #fff;
    padding-right: 31px
}

.carousel-item .modalcard .card-body {
    padding: 10px 10px 0px 10px;
}

.carousel-item .modalcard .card-body p {
    overflow: hidden;
    text-align: center;
    padding-top: 10px;
    line-height: 34px;
    font-weight: 100;
    font-size: 16px;
}

.carousel-item .modalcard {
    border: none;
    margin-bottom: 0;
}

.carousel-item .modal-body {
    padding: 0px 1rem;
}

#exampleModalToggle .modal-content {
    padding: 0 0 0px;
}

.carousel-item button.btn-close:focus {
    box-shadow: none
}

.carousel-item a.btn.btn-primary.btn-sm {
    background: #d9281f;
    border: #d9281f;
    margin: 0 10px;
    color: #fff;
}

.carousel-item a.btn.btn-primary.btn-sm:focus {
    box-shadow: none;
    border: 1px solid #182b3c;
}



.carousel-item .btn-close {
    opacity: 1;
    position: relative;
    z-index: 2;
    }



button.accordion-button, .accordion-button:not(.collapsed) {
    color: inherit;
}
.m-auto {
    margin: 30px auto 60px!important;
    display: block !important;
    width: max-content;
}

.kinder .project-item {
    width: 98%;
    margin-top: 40px;
    background: #fdfbe7;
    padding: 25px;
    border: 15px solid #fff;
    outline: 1px solid #ccc;
    outline-offset: -20px;
    box-shadow: 0px 2px 7px 0px #ccc;
    border-radius: 15px;
}
.kinderbox {
    margin-left: 160px;
}
.kinder {
    background: url('https://webapi.entab.info/api/image/LCSD/public/Images/kinder.png');
    background-size: 30% 80%;
    background-repeat: no-repeat;
    margin-left: 100px;
}
.kinder .section-title.title-style-two.text-center.mb-40.tg-heading-subheading.animation-style2 {
    margin-top: 50px;
    margin-bottom: 0;
}
.kidcorner {
    padding: 60px 80px 20px 0px;
}
.kids .page-header-bg {
    background-image: url('https://webapi.entab.info/api/image/LCSD/public/Images/bread-kids.png') !important;
    background-size: auto 100% !important;
    background-position: left !important;
    filter: drop-shadow(2px 4px 6px black);
}
section.page-header.kids {
    background: url('https://webapi.entab.info/api/image/LCSD/public/Images/bg-img.jpg') no-repeat;
    background-size: 100% auto;
    background-position: bottom;
}
.page-header-shape-1 {
    width: 30%;
    background: #fff;
    opacity: 1;
    mix-blend-mode: unset;
    opacity: 0.3;
}

.kids .page-header-bg:before {
    background: transparent;
} 

.kids .page-header__inner {
    width: max-content;
    background: #ffffff9c;
    padding: 6px 15px 4px;
    border-radius: 7px;
}

.kids .page-header__inner li a {
    color: #000;
}
.fulwdh {
    padding: 30px 60px;
}


.bd-hero-shape-2 {top: 230px;inset-inline-end: 110px;animation: bd-updown-2 2s infinite alternate-reverse;display:none;}
.bd-hero-shape {position: absolute;}
.bd-hero-shape-1 {bottom: 100px;inset-inline-start: 110px;animation: bd-updown-2 2s infinite alternate-reverse;display:none;}
@keyframes bd-updown {
0% {transform: translateX(0);}
100% {transform: translateX(-30px);}
}
@keyframes bd-updown-2 {
0% {transform: translateY(-50px);}
100% {transform: translateY(0);}
}

.elementor-widget-wrap { position: relative; width: 100%; flex-wrap: wrap; align-content: flex-start; } 
.elementor-widget-wrap>.elementor-element { width: 100%; } 
.elementor-element { --widgets-spacing: 20px; } 
.feature__list {position: relative;display: flex;align-items: center;flex-wrap: wrap;background-color: white;box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);z-index: 1;} 
.feature__list li {position: relative;flex: 0 0 33.333333%;max-width: 33.333333%;width: 100%;padding: 0 0 22px;} 
.feature__single {position: relative;display: block;padding: 40px 40px 0px;margin-bottom: 22px;}
.elementor *, .elementor :after, .elementor :before { box-sizing: border-box;}
.feature__list li:before {content: "";position: absolute;top: 0;bottom: 0;right: 0;width: 1px;background-color: #b5afb138;}
.feature__icon {position: relative;display: flex;align-items: center;justify-content: center;height: 85px;width: 85px;background-color: #bd2050;border-radius: 50%;overflow: hidden;z-index: 1;/* background: linear-gradient( to right,  #b20e40, #fcb045); */}
.feature__icon span {position: relative;display: inline-block;font-size: 62px;color: #ffffff;transition: all 500ms linear;transition-delay: 0.1s;transform: scale(1);}
.feature__title {font-size: 17px;font-weight: 700;line-height: 34px;padding-top: 15px;margin-bottom: 5px;}
.feature__icon:after { position: absolute; top: 0; bottom: 0; left: 0; right: 0; height: 100%; content: ""; background-color: #023a51; border-radius: 50%; -webkit-transition-delay: .1s; transition-delay: .1s; -webkit-transition-timing-function: ease-in-out; transition-timing-function: ease-in-out; -webkit-transition-duration: .4s; transition-duration: .4s; -webkit-transition-property: all; transition-property: all; opacity: 1; -webkit-transform-origin: top; transform-origin: top; -webkit-transform-style: preserve-3d; transform-style: preserve-3d; -webkit-transform: scaleY(0); transform: scaleY(0); z-index: -1; }
.elementor a { box-shadow: none; text-decoration: none; } 
.feature__title a {color: #000000;-webkit-transition: all 500ms ease;transition: all 500ms ease;} 
.feature__icon span img {width: 45px;height: 45px;}
.feature__icon:after {position: absolute;top: 0;bottom: 0;left: 0;right: 0;height: 100%;content: "";background-color: #3b3a3a;background: linear-gradient( to right,  #fcb045, #b20e40);border-radius: 50%;-webkit-transition-delay: .1s;transition-delay: .1s;-webkit-transition-timing-function: ease-in-out;transition-timing-function: ease-in-out;-webkit-transition-duration: .4s;transition-duration: .4s;-webkit-transition-property: all;transition-property: all;opacity: 1;-webkit-transform-origin: top;transform-origin: top;-webkit-transform-style: preserve-3d;transform-style: preserve-3d;-webkit-transform: scaleY(0);transform: scaleY(0);z-index: -1;} 
section.feature {padding-top: 30px;} 
.feature__single:hover .feature__icon span { transform: scale(0.9); } 
.feature__icon span { position: relative; display: inline-block; font-size: 62px; color: #ffffff; transition: all 500ms linear; transition-delay: 0.1s; transform: scale(1); } 
.feature__single:hover .feature__icon:after { opacity: 1; -webkit-transform: scaleY(1); transform: scaleY(1); }
.feature__list li:last-child .feature__single:before {  display: none;}

.about-sec {padding:50px 0px 20px 0px;
    background: linear-gradient(39deg, white 65%, #ffce51 60.5%);;}
.about-sec__left {position: relative; display: block;}
.about-sec__img { position: relative; display: block; border-radius: 50%; overflow: hidden; z-index: 1; } 
.elementor img { height: auto; max-width: 100%; border: none; border-radius: 0; box-shadow: none; } 
.about-sec__img img { width: 100%; border-radius: 50%; } 
.about-sec__img-two { position: absolute; top: 40px; left: -60px; z-index: 2; } 
div.about-sec__img-two img { width: auto; border-radius: 50%; } 
.elementor img { height: auto; max-width: 100%; border: none; border-radius: 0; box-shadow: none; } 
.about-sec__call {position: absolute;bottom: 102px;left: -60px;background-color: white;box-shadow: 14.982px 29.403px 87px 0px rgba(0, 0, 0, 0.1);display: flex;align-items: center;padding: 25px 40px 25px;border-top-right-radius: 50px;border-bottom-left-radius: 50px;z-index: 2;} 
.about-sec__call-icon {position: relative;display: flex;align-items: center;justify-content: center;height: 50px;width: 50px;border-radius: 50%;-webkit-transition: all 500ms ease;transition: all 500ms ease;} 
.about-sec__call-icon i { position: relative; display: inline-block; font-size: 23px; color: #bd2050; -webkit-transition: all 500ms ease; transition: all 500ms ease; } 
.about-sec__call-content { margin-left: 10px; } 
.about-sec__call-sub-title { font-size: 18px; line-height: 0px; margin-bottom: 0px; } 
.about-sec__call-number {font-size: 16px;font-weight: 700;margin-top: 8px;} 
.about-sec__shape-1 { position: absolute; bottom: 0; left: -17px; height: 55px; width: 55px; background-color: #bd2050; border-radius: 50%; } 
.zoominout { animation-name: zoomInOut; animation-duration: 3s; animation-iteration-count: infinite; animation-timing-function: ease-in-out; -webkit-animation-name: zoomInOut; -webkit-animation-duration: 3s; -webkit-animation-iteration-count: infinite; -webkit-animation-timing-function: ease-in-out; -moz-animation-name: zoomInOut; -moz-animation-duration: 3s; -moz-animation-iteration-count: infinite; -moz-animation-timing-function: ease-in-out; -ms-animation-name: zoomInOut; -ms-animation-duration: 3s; -ms-animation-iteration-count: infinite; -ms-animation-timing-function: ease-in-out; -o-animation-name: zoomInOut; -o-animation-duration: 3s; -o-animation-iteration-count: infinite; -o-animation-timing-function: ease-in-out; } 
.about-sec__arrow { position: absolute; bottom: -40px; right: -47px; z-index: -1; } 
.elementor img { height: auto; max-width: 100%; border: none; border-radius: 0; box-shadow: none; } 
.about-sec__arrow img { width: auto; } 
.fa-phone:before { content: "\f590"; font-family: bootstrap-icons !important; } 
.about-sec__right { position: relative; display: block; margin-left: 70px;    position: relative;
    display: block;
    margin-left: 70px;
    background: #fff;
    padding: 50px;
    border-radius: 50px;
    box-shadow: -25px 26px 27.9px -26px #00000033; } 
    .aboutsecdesc b {
        color: #6c3db8;
    }
.about-sec__right .section-title { margin-bottom: 16px; } 
.section-title { position: relative; display: block; margin-top: -5px; margin-bottom: 39px; } 
.about-sec__points-and-counter-box { position: relative; display: flex; align-items: center; flex-wrap: wrap; margin-top: 37px; } 
.about-sec__points-box { position: relative; display: block; } 
.about-sec__call-number a:hover { color: #bd2050; } .elementor a { box-shadow: none; text-decoration: none; } 
.about-sec__call-number a { color: #000000; -webkit-transition: all 500ms ease; transition: all 500ms ease; }
.section-title__slash-one {position: relative;display: inline-block;color: #edab00;padding-right: 4px;} 
.section-title__slash-two {position: relative;display: inline-block;color: #edab00;padding-left: 4px;} 
.section-title{font-size: 31px;font-weight: 600;line-height: 48px;margin-top: 5px;color: #000000;text-transform:uppercase;font-family: 'Poppins', sans-serif;} 
.section-title__sub-title {text-transform: uppercase;letter-spacing: 1px;padding: 0px;text-align: center;margin-bottom: 0px;} 
@keyframes zoomInOut  {
0% { -webkit-transform: rotate(0deg) scale(0.7); transform: rotate(0deg) scale(0.7); opacity: 0; } 
50% { -webkit-transform: rotate(180deg) scale(1); transform: rotate(180deg) scale(1); opacity: 1; } 
100% { -webkit-transform: rotate(360deg) scale(0.7); transform: rotate(360deg) scale(0.7); opacity: 0;} 
} 
.about-sec__btn-box {   position: relative;
    display: block;
    text-align: center;
    margin-bottom: 30px; } 
.thm-btn {box-shadow: 0px 28px 15.4px -25px #0000008C;
    position: relative;display: inline-block;vertical-align: middle;-webkit-appearance: none;appearance: none;outline: none !important;background:linear-gradient(180deg, #3E236A 0%, #7A45D0 100%);color: #ffffff;font-size: 13px;font-weight: 700;padding: 15px 25px 15px;transition: all 0.5s linear;overflow: hidden;z-index: 1;text-transform: uppercase;letter-spacing: 2px;} 
.thm-btn:before {position: absolute;content: '';background-color: #edab00;width: 120%;height: 0;padding-bottom: 120%;
top: -110%;left: -10%;border-radius: 50%;transform: translate3d(0, 68%, 0) scale3d(0, 0, 0);z-index: -1;} 
.thm-btn:after {position: absolute;top: 0;left: 0;width: 100%;height: 100%;content: '';background-color: #edab00;transform: translate3d(0, -100%, 0);transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);z-index: -1;} 
.thm-btn:hover { color: #ffffff; } 
.elementor a { box-shadow: none; text-decoration: none; } 
.thm-btn:hover::before { transform: translate3d(0, 0, 0) scale3d(1, 1, 1); transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1); } 
.thm-btn:hover::after { transform: translate3d(0, 0, 0); transition-duration: 0.05s; transition-delay: 0.4s; transition-timing-function: linear; }
.aboutaudio {
    background: #f5f5f594;
    padding: 20px;
    border-radius: 30px;
}

.aboutaudiobox {
    display: flex;
}

.aboutaudiobox button {
    margin-left: 20px;
    border: 1px solid #553092;
    background: #fff;
    padding: 10px;
    box-shadow: 0px 3px 6.9px -11px #00000061;
    margin-bottom: 10px;
    border-radius: 5px;
}
.why-choose-three hr{margin: 20px 0px;background-color: #fff;}
.why-choose-three {position: relative;display: block;z-index: 1;    margin-top: -40px;} 
.why-choose-three__shape-4 { position: absolute; top: 0; left: -375px; mix-blend-mode: multiply; opacity: .50; z-index: -1; } 
.why-choose-three__shape-4 img { width: auto; } 
.aboutimg {
    clip-path: polygon(26% 0, 100% 0%, 100% 100%, 0 100%);
    position: absolute;
    top: -30px;
    right: 0;
    background: url('https://webapi.entab.info/api/image/LCSD/public/Images/why-choose-three-img.jpg');
}
.newnews {
    margin-top: -40px;
    position: relative;
        background: url('https://webapi.entab.info/api/image/LCSD/public/Images/why-choose-three-img.jpg') no-repeat right bottom;
        background-size: 70% 130%;
        padding: 50px 70px;
    }
    .newnews .why-choose-three__btn-box {
        justify-content: center;
        margin-top: 50px;
    }
    .newnews:before {
        content: ' ';
        position: absolute;
        background:linear-gradient(270deg, #ffffff00 54%, #ffce51 54%);
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
    .why-choose-three__btn-box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 30px;
        margin-top: 0px;
    }
    .newssec-area marquee {
        height:320px;
    }
    .newnews .section-title-two__sub-title-box {
        position: relative;
    }
    .newbox:after {
        position: absolute;
        content: ' ';
        width: 25%;
        top: 0;
        left: 0;
        border-bottom: 1px dashed #fff;
    }
    .newbox:first-child:after {
        border: 0px;
    }
    .newbox {
        position: relative;
        padding-top: 15px;
    }
.why-choose-three__left { position: relative; display: block; padding-top: 120px; padding-bottom: 120px; z-index: 1; } 
.why-choose-three__left:before {position: absolute;top: 0;left: -10000000px;right: -201px;bottom: 0;content: "";background-color: #ffcd50;z-index: -1; transform: skewX(-24deg); } 
.why-choose-three__left .section-title-two { margin-bottom: 20px; } 
.why-choose-three__left .section-title-two__sub-title { color: #83a9b8; text-transform: uppercase; letter-spacing: 1px; padding: 0px; margin-bottom: 0px; } 
.why-choose-three__left .section-title-two__title { color: #333; margin-bottom: 0px; } 
.why-choose-three__img img { width: 100%; }

.newssec-area-box-con h2 { font-size: 19px; font-weight: normal; line-height: 30px; color: #333; padding-bottom: 25px; -webkit-transition: all .3s; transition: all .3s; text-transform: uppercase; letter-spacing: 1px; text-align: justify; overflow: hidden; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; height: 1.1em; text-overflow: ellipsis; text-align: left; margin-bottom: 12px } 
.newssec-area-box-con p {     text-transform: uppercase;padding: 0; color: #333; text-align: justify; overflow: hidden; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; text-overflow: ellipsis; font-weight: 500; line-height: 26px; font-style: normal } 
.newssec-top { padding-top: 20px } 
.newssec-area-box-con h6 { color: #fff !important; font-size: 14px; line-height: 15px; color: #29166f; margin-bottom: 12px; display: inline-flex; justify-content: center; } 
.newssec-area-box-con h6 i { padding: 0; color: #333; font-size: 14px; line-height: 0; margin-right: 6px; display: inline-flex; justify-content: center; } 
.newssec-area-box-con h6 a:hover { color: #ffce51; } 
.newssec-area-box-con h6 a { color: #ffffff; margin-right: 12px; display: inline-flex; justify-content: center; }
.newssec-area-box-con h6 a {
    display: flex;
    flex-direction: column;
}
.newssec-area-box-con h6 span {
    background: linear-gradient(180deg, #3E236A 0%, #7A45D0 100%);
    padding: 10px;
    margin-bottom: 12px;
    margin-top: 8px;
    box-shadow: 0px 16px 8px -11px #00000059;
    text-align: center;
}
.lcsd-choose-wrap { padding-top: 80px; padding-bottom: 80px;} 
.lcsd-section { margin-bottom: 25px; } 
.lcsd-section-title { margin-bottom: 35px; } 
.lcsd-section-text { margin-bottom: 0!important; margin-right: 70px; } 
.lcsd-choose-contain-qoute {background-color: #f4f5f9;padding: 20px 30px;/* display: flex; */gap: 20px;margin-bottom: 15px;letter-spacing: 0.3px;color: #303131;font-size: 16px;font-weight: 500;text-align: justify;line-height: 29px;font-family: 'Roboto', sans-serif;} 
.lcsd-choose-contain-qoute span { display: inline-block; font-size: 28px; color: #20bd8d; } 
.lcsd-choose-contain-qoute span img {width: 50px;padding-bottom: 16px;}
.lcsd-choose-contain-qoute p { margin-bottom: 0; font-style: italic; line-height: 26px; }
.lcsd-theme-btn {line-height: 50px;height: 50px;font-size: 17px;padding: 0 35px;font-weight: 500;display: inline-block;color: #fff;border-radius: 4px;background: #eb721f;cursor: pointer;margin-bottom: 0;text-align: center;text-transform: capitalize;touch-action: manipulation;transition: all 0.3s ease 0s;white-space: nowrap;border: none;display: inline-flex;align-items: center;justify-content: center;position: relative;z-index: 1;}
.lcsd-choose-img { width: 545px; height: 545px; border-radius: 50%; overflow: hidden; border: 10px solid #fff; background-position: right center; margin-left: auto; } 
.lcsd-feature-single.bg-default { padding-top: 80px; padding-bottom: 120px; } 
.lcsd-choose-area { position: relative; z-index: 1; overflow: hidden; } 
.designation { text-align: right; line-height: 23px; } 
.designation span { font-size: 15px; color: #20bd8d; letter-spacing: 1px; }

.gal-sec {padding: 80px 0px 50px 0px; background: #f4f5f9;}
.swiper-slide {flex-shrink: 0;width: 100%;height: 100%;position: relative;transition-property: transform;}
.lcsd-project-item {position: relative;overflow: hidden;}
.lcsd-project-item .lcsd-project-img img {height: 370px;object-fit: cover;background: #f1f1f1;border-radius: 6px;width: 100%;}
.lcsd-project-content {position: absolute;left: 0;bottom: 0;width: 100%;}
.lcsd-project-content-info { background-color: #2b0e0ce3; opacity: 0.89; padding: 20px 20px 20px 20px; border-top: 3px solid #fff; position: absolute; left: 0; bottom: -100px; right: 0; z-index: 1; -webkit-transition: all 0.4s linear 0s; -moz-transition: all 0.4s linear 0s; -ms-transition: all 0.4s linear 0s; -o-transition: all 0.4s linear 0s; transition: all 0.4s linear 0s; border-top-right-radius: 100px; margin-right: 50px; }
.lcsd-project-content-title {color: #fff;font-size: 22px;margin-bottom: 5px;}
.lcsd-project-content-info span {color: #ffffff;display: inline-block;font-size: 13px;}
.lcsd-project-content-btn { position: absolute; right: -210px; bottom: -100px; width: 200px; height: 200px; background: #eb721f; border-radius: 50%; z-index: 2; -webkit-transition: all 0.4s linear 0s; -moz-transition: all 0.4s linear 0s; -ms-transition: all 0.4s linear 0s; -o-transition: all 0.4s linear 0s; transition: all 0.4s linear 0s; }
.lcsd-project-content-btn a { position: absolute; top: 40px; left: 45px; z-index: 3; width: 40px; height: 40px; background-color: #fff; display: inline-block; border-radius: 50%; line-height: 40px; text-align: center; font-size: 18px; -webkit-transition: all 0.4s linear 0s; -moz-transition: all 0.4s linear 0s; -ms-transition: all 0.4s linear 0s; -o-transition: all 0.4s linear 0s; transition: all 0.4s linear 0s; color: #eb721f; }
.lcsd-project-shape-1 { background-color: #eb721f; opacity: 0.922; top: -245px; top: -350px; right: -120px; z-index: 2; -webkit-transition: all 0.4s linear 0s; -moz-transition: all 0.4s linear 0s; -ms-transition: all 0.4s linear 0s; -o-transition: all 0.4s linear 0s; transition: all 0.4s linear 0s; }
.lcsd-project-shape {position: absolute;content: "";width: 330px;height: 330px; border-radius: 50%;}
.lcsd-project-shape-2 { background-color: #2b0e0ce3; opacity: 0.922; top: -170px; right: -210px; right: -350px; z-index: 1; -webkit-transition: all 0.4s linear 0s; -moz-transition: all 0.4s linear 0s; -ms-transition: all 0.4s linear 0s; -o-transition: all 0.4s linear 0s; transition: all 0.4s linear 0s; }
.lcsd-project-shape { position: absolute; content: ""; width: 330px; height: 330px; border-radius: 50%; } 
.lcsd-project-item { position: relative; overflow: hidden; } 
.lcsd-project-item:hover .lcsd-project-content-info { bottom: 0; } 
.lcsd-project-item:hover .lcsd-project-content-btn { right: -95px; } 
.lcsd-project-item:hover .lcsd-project-content-btn a { transform: scale(1); } 
.lcsd-project-content-title:hover a { color: #eb721f; } 
.lcsd-project-item:hover .lcsd-project-shape-1 { top: -245px; } 
.lcsd-project-item:hover .lcsd-project-shape-2 { right: -210px; } 
.lcsd-project-content-title { color: #fff; font-size: 15px; margin-bottom: 5px; text-transform: uppercase; overflow: hidden; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; height: 1.1em; text-overflow: ellipsis; } 
.lcsd-project-content-title a { color: #fff; } 
.lcsd-project-content-btn a:hover { background-color: #242424; color: #fff; } 
.bg-default { background-size: cover; background-position: center center; background-repeat: no-repeat; }

.midsecbox1 {
    display: flex;
    width: 40%;
}
.midsecbox2 {
    display: flex;
    justify-content: center;
    width: 20%;
}
.midsecboxsecimg {
    width: 40px;
}
.midsec {
    position: relative;
    margin-top: -50px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    z-index: 1;
    flex-direction: row;
    justify-content: space-between;
}
.midsecboxsec {
    box-shadow: 0px 28px 15.4px -25px #0000008c;
    border-radius: 5px;
    display: flex;
    background: linear-gradient(180deg, #3E236A 0%, #7A45D0 100%);
    color: #fff;
    width: 100%;
    padding: 5px 10px;
    margin: 0px 10px;
    align-items: center;
}
.midsecboxsecimg img {
    width: 35px;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 3px;
}
.midsecboxsecdesc p {
    color: #fff;
    padding-left: 10px;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 0 !important;
    padding-bottom: 0px;
    text-transform: uppercase;
}
.midsecboxdiv {
    border-radius: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #fdfbff 0%, #ffffff 100%);
    box-shadow: 0px 0px 31.3px 0px #3e236aa6 inset;
    width: 70px;
    padding-top: 11px;
    height: 70px;
}
.midsecbox1 .yel {
    background: linear-gradient(180deg, #EDAB00 0%, #FFB339 100%);
}
.midsecbox1 .yel .midsecboxsecimg img {
    border: 1px solid #000;
}
.yel .midsecboxsecdesc  p {
    color: #000 !important;
}
.activitesboxdesc h6 {
    font-size: 20px;
    color: #ffff;
    text-align: center;
    line-height: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 0;
}
.activitesboxdesc span {
    text-align: center;
    display: block;
    font-size: 12px;
    color: #fc4b81;
    padding: 5px;
}
.activitesboximg img {
    height: 300px;
    width: 100%;
}
.activitesbg {
    padding: 50px;
    border-bottom: 1px solid #edcb0b;
    border-top: 1px solid #edcb0b;
    border-right: 1px solid #edcb0b;
    display: flex;
    flex-direction: row;
    position: relative;
}
.activitestitle p {
    text-align: left;
    color: #edcb0b;
    text-transform: uppercase;
}
.activitestitle h2 {
    color: #fff;
    text-transform: uppercase;
    font-size: 25px;
    line-height: 39px;
}
.container-fluid.activitesfulwdh {
    padding: 0px 50px 10px 340px;
}
.actititle {
    width: 30%;
    margin-left: -130px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.activitesbg:after {
    position: absolute;
    content: ' ';
    width: 100%;
    height: 30%;
    border-left: 1px solid #edcb0b;
    top: 0;
    left: 0;
}
.activitesbg:before {
    position: absolute;
    content: ' ';
    width: 100%;
    height: 30%;
    border-left: 1px solid #edcb0b;
    bottom: 0;
    left: 0;
}
.activitesbox {
    width: 90%;
}
.activitiesslide {
    width: 80%;
}
.activitesboxdesc p {
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    color: #ffff;
    margin-bottom: 0;
}
.activites{     padding:30px 0px; background:linear-gradient(-45deg, rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)) , url('https://webapi.entab.info/api/image/LCSD/public/Images/activitiesbg.jpg'); background-size: cover; background-position: top center;
}
.footer-bg { background-image: url('https://webapi.entab.info/api/image/LCSD/public/Images/footer_bg.png'); background-size: cover; background-position: top center; } 
.footer-top {padding: 215px 0 25px;} 
.footer-widget { margin-bottom: 40px; } 
.footer-widget .fw-logo {margin-bottom: 24px;/* max-width: 168px; */} 
.footer-content p {margin-bottom: 20px;color: #d7d7d7;text-align: left;} 
.footer-content p a{color: #d7d7d7;} 
.footer-content p a:hover{color: #20bd8d;opacity: 1; margin-left: 5px;} 
.footer-content .list-wrap {display: flex;align-items: center;gap: 7px;justify-content: center;} 
.footer-social .list-wrap li a, .footer-content .list-wrap li a {width: 35px;height: 35px;display: flex;align-items: center;justify-content: center;font-size: 15px;background: #ffffff;border-radius: 50%;color: #e60023;}
.footer-social .list-wrap li a:hover, .footer-content .list-wrap li a:hover {color: #fff;background: #e60023;} 

.fw-title .title { display: inline-block; margin-bottom: 35px; color: #ffffff; position: relative; font-size: 20px; padding-left: 50px; }
.fw-title .title::before { content: ""; position: absolute; left: 0; top: 50%; transform: translateY(-50%); width: 30px; height: 3px; background: #20bd8d; } 
.fw-link .list-wrap li {margin-bottom: 7px;list-style: none;} 
.fw-link .list-wrap li:last-child { margin-bottom: 0; } 
.fw-link .list-wrap li a {color: #d7d7d7;position: relative;opacity: .8;text-transform: capitalize;} 
.fw-link .list-wrap li a::before { content: ""; position: absolute; left: 0; bottom: 0; width: 100%; height: 1px; background: #20bd8d; -webkit-transform-origin: right top; -ms-transform-origin: right top; transform-origin: right top; -webkit-transform: scale(0, 1); -ms-transform: scale(0, 1); transform: scale(0, 1); transition: transform 0.4s cubic-bezier(.74, .72, .27, .24); } 
.fw-link .list-wrap li a:hover::before { -webkit-transform-origin: left top; -ms-transform-origin: left top; transform-origin: left top; -webkit-transform: scale(1, 1); -ms-transform: scale(1, 1); transform: scale(1, 1); }
.fw-link .list-wrap li a:hover { color: #20bd8d; opacity: 1; margin-left: 5px; } 
.footer-bottom { border-top: 1px solid #0b3f54; padding: 10px 0; } 
.copyright-text p { margin-bottom: 0; color: #aaa; text-align: center; font-size: 14px; padding: 0px; } 
.copyright-text p span { color: #fff; } 
.copyright-text p span a { color: #fff; } 
.copyright-text p span a:hover { color:#20bd8d; } 
.footer-menu .list-wrap { display: flex; align-items: center; justify-content: flex-end; gap: 10px 20px; } 
.footer-menu .list-wrap li a { color: #aaa; } 
.footer-menu .list-wrap li a:hover { color: #0554f2; }

.newsletter-bg img { position: absolute; z-index: -2; } 
.newsletter-inner { position: relative; z-index: 1; padding: 90px 60px 50px; overflow: hidden; } 
.newsletter-bg svg { fill: #119a70; fill-rule: evenodd; width: 100%; } 
.newsletter-bg { position: absolute; left: 0; top: 0; height: 100%; z-index: -1; } 
.newsletter-content .title { font-size: 28px; line-height: 1.47; margin-bottom: 0; color: #ffffff; text-align: left; display: block; } 
.newsletter-contact { display: flex; align-items: center; } 
.newsletter-contact .icon { width: 60px; height: 60px; display: flex; align-items: center; justify-content: center; background: transparent; border: 1px solid rgb(45 214 163); color: #ffffff; border-radius: 50%; font-size: 24px; line-height: 0; margin-right: 20px; } 
.newsletter-contact .content span { display: block; text-transform: uppercase; font-size: 15px; color: #ffffff; margin-bottom: 10px; } 
.newsletter-contact .content a { color: #ffffff; font-weight: 700; font-size: 30px; line-height: 1; }

.project-area {position: relative;display: block;padding: 80px 0 160px;background-color: #e5eff2;z-index: 1;} 
.project-area:before {content: "";position: absolute;bottom: 0;left: 0;right: 0;height: 370px;background-color: #fff;z-index: -1;}
.project-bg {position: absolute;top: 0;bottom: 0;left: 0;right: 0;background-repeat: no-repeat;background-position: center;background-size: cover;mix-blend-mode: soft-light; opacity: .60;z-index: -1;}
.project-item-wrap .row .col-20 { width: 20%; flex: 0 0 auto; padding: 0 15px; } .project-item { position: relative; overflow: hidden; margin-bottom: 30px; } 
.project-thumb { border-radius: 10px; overflow: hidden; } 
.project-thumb img { border-radius: 10px; transition: .3s linear; transform: scale(1);    height: 250px;
    object-fit: contain;margin: auto } 
.img-blur.active .project-thumb img { filter: grayscale(100%); } 
.project-item:hover .project-thumb img { transform: scale(1.1);   filter: grayscale(100%);-webkit-filter: grayscale(100%);-moz-filter: grayscale(100%);} 
.project-content { text-align: center; position: absolute; background: #fff; left: 40px; right: 40px; bottom: 35px; border-radius: 5px; transition: .3s; padding: 17px 10px; transform: translateY(200px); } 
.project-item:hover .project-content { transform: translateY(0); } 
.project-content .title {margin-bottom: 5px;font-size: 18px;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;color:#000;}
.project-content span {display: block;color: #20bd8d;letter-spacing: 1px;line-height: 1.2;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;display:none;display:none;} 
.project-content .title a { margin-bottom: 5px; font-size: 18px; color: #1b1d21; } 
.img-blur.active .project-thumb img { filter: grayscale(100%); }
.custom-container-two { max-width: 1890px; } 
.project-area {position: relative;z-index: 1;padding-top: 115px;padding-bottom: 85px;} 
.fix { overflow: hidden; } 
.gal-big img{height: 330px;width:100%;object-fit: contain;background: linear-gradient( to right, #e7e7e7, #ffffff);}
.gal-small img{height: 300px;width:100%;object-fit: contain;background: linear-gradient( to right, #e7e7e7, #ffffff);}

.hide-icon .gray-bg{
    background: #eeeeee !important
}
.bg-silver-light:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 370px;
    background-color: #fff;
    z-index: -1;
}


.event-sec{position: relative;display: block;padding: 80px 0 80px;background-color: #e5eff2;z-index: 1;}
.event-sec:before {content: "";position: absolute;bottom: 0;left: 0;right: 0;height: 370px;background-color: #fff;z-index: -1;}
.services-four__bg {position: absolute;top: 0;bottom: 0;left: 0;right: 0;background-repeat: no-repeat;background-position: center;background-size: cover;mix-blend-mode: soft-light;/* opacity: .60; */z-index: -1;}
.event-sec {position: relative;display: block;}
p.section-title__sub-title.text-left {text-align: left;}
.bd-blog {border-radius: 24px;overflow: hidden;}
.bd-blog-content-2 {padding-inline-start: 52px;border-inline-start: 0;-webkit-transition: all 0.3s ease-out 0s;-moz-transition: all 0.3s ease-out 0s;-ms-transition: all 0.3s ease-out 0s;-o-transition: all 0.3s ease-out 0s;transition: all 0.3s ease-out 0s;}
.bd-blog-content {position: relative;padding: 17px 25px;border: 1px solid #e6e6e6;border-top: 0;border-radius: 0 0 24px 24px;padding-top: 20px;}
.bd-blog-thumb::before {content: "";position: absolute;top: 0;left: -75%;z-index: 2;display: block;width: 50%;height: 100%;background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);transform: skewX(-25deg);}
.bd-blog-thumb img {width: 100%;height: 100%;object-fit: contain;-webkit-transition: all 0.3s ease-out 0s;-moz-transition: all 0.3s ease-out 0s;-ms-transition: all 0.3s ease-out 0s;-o-transition: all 0.3s ease-out 0s;transition: all 0.3s ease-out 0s;background: #e7e7e7;}
.bd-blog-content-2 { padding-inline-start: 52px; border-inline-start: 0; -webkit-transition: all 0.3s ease-out 0s; -moz-transition: all 0.3s ease-out 0s; -ms-transition: all 0.3s ease-out 0s; -o-transition: all 0.3s ease-out 0s; transition: all 0.3s ease-out 0s; } 
.bd-blog-content {} 
.bd-blog-date-2 { position: absolute; bottom: 0; height: 100%; writing-mode: vertical-lr; left: 0; transform: rotate(-180deg); } 
.bd-blog-date-2 span { background: #bd2050; color: #ffffff; padding: 0px 2px; height: 100%; display: inline-block; text-align: center; letter-spacing: 1px; } 
.bd-blog-meta { display: flex; align-items: center; gap: 16px; margin-bottom: 20px; } 
.bd-blog-meta span { font-size: 16px; display: flex; gap: 6px; align-items: center; } 
.bd-blog-meta span i { color: #bd2050; } 
.bd-blog-meta span { font-size: 16px; display: flex; gap: 6px; align-items: center; }
.bd-blog-title {line-height: 28px;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;height: 60px;font-size: 17px;font-weight: normal;padding-bottom: 25px;-webkit-transition: all .3s;transition: all .3s;text-transform: uppercase;text-align: left;margin-bottom: 12px;} 
.bd-blog-content {position: relative;padding: 17px 25px;border: 1px solid #e6e6e6;border-top: 0;border-radius: 0 0 24px 24px;padding-top: 20px;background: white;} 
.bd-blog-meta a { color: #2d2c2c; } 
.bd-blog-title:hover { color: #bd2050; } 
.bd-blog-title a { color: black; } 
.bd-blog:hover .bd-blog-content-2 { background-color: #e8f1f3; border-color: #e9f2f4; } 
.bd-blog-content-2 { padding-inline-start: 52px; border-inline-start: 0; -webkit-transition: all 0.3s ease-out 0s; -moz-transition: all 0.3s ease-out 0s; -ms-transition: all 0.3s ease-out 0s; -o-transition: all 0.3s ease-out 0s; transition: all 0.3s ease-out 0s; } 
.bd-blog-thumb::before {content: "";position: absolute;top: 0;left: -75%;z-index: 2;display: block;width: 50%;height: 100%;background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);transform: skewX(-25deg);} 
.bd-blog-thumb:hover img { transform: scale(1.1); } 
.bd-blog-thumb:hover::before { animation: shine 800ms; } 
.bd-blog {border-radius: 24px;
    overflow: hidden;
    margin-bottom: 30px;
    width: 96%;
    margin: auto;margin-bottom: 30px;} a { -webkit-transition: all 0.3s ease-out 0s; -moz-transition: all 0.3s ease-out 0s; -ms-transition: all 0.3s ease-out 0s; -o-transition: all 0.3s ease-out 0s; transition: all 0.3s ease-out 0s; } 
.bd-blog-thumb {width: 100%;height: 277px;overflow: hidden;-webkit-transition: all 0.3s ease-out 0s;-moz-transition: all 0.3s ease-out 0s;-ms-transition: all 0.3s ease-out 0s;-o-transition: all 0.3s ease-out 0s;transition: all 0.3s ease-out 0s;position: relative;}
@keyframes shine {
  100% {left: 125%;}
}
@keyframes swell {
  0%, 100% {transform: translate3d(0, -25px, 0);}
  50% {transform: translate3d(0, 5px, 0);}
}ul#event-slider {
    margin-bottom: 30px;
}
.topbar marquee a {
    color: #fff;
}
.topbar p {
    margin-bottom: 0;
    padding-bottom: 0;
    color: #fff;
    min-height: 30px;
}

.quicklinks-sec{position: relative;display: block;padding: 80px 0 160px;background-color: #e5eff2;z-index: 1;}

.topper-section.services-style-current-theme.Birthdaybg {background: #0089b7;}
.z-index-1{z-index:1!important}
.bg-silver-light{background-color: #e5eff2!important;padding-top: 70px;padding-bottom: 80px;position: relative;}
.topper-service p {text-align: center;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;margin-bottom: 0;color: #ffffff;text-transform: uppercase;font-size: 15px;padding-bottom: 0;}
.topper-info-list {margin-bottom: 0px;}
.services-style-current-theme{background:#fff;padding: 25px 10px;border-radius:20px;box-shadow: 0 0 30px rgb(156 154 154 / 11%);height: 324px;margin: 4px;/* border: dotted 1px #bd2050; */background: linear-gradient(110deg, #073e54 60%, #0e4359 60%);}
.services-style-current-theme .topper-service .thumb{position:relative;margin-bottom: 25px;overflow:hidden}
.services-style-current-theme .topper-service .thumb::before{position:absolute;top:0;left:-75%;z-index:2;display:block;content:'';width:50%;height:100%;
background:-webkit-linear-gradient(left,rgba(255,255,255,0) 0%,rgba(255,255,255,0.3) 100%);
background:linear-gradient(to right,rgba(255,255,255,0) 0%,rgba(255,255,255,0.3) 100%);-webkit-transform:skewX(-25deg);transform:skewX(-25deg)}

.services-style-current-theme:hover .topper-service .thumb::before{-webkit-animation:shine .85s;animation:shine .85s}
.services-style-current-theme:hover .topper-service .thumb .service-icon{transform:rotateY(180deg)}
.services-style-current-theme:hover .topper-service .thumb .service-icon img{transform:scale(1.05)}
@-webkit-keyframes circle {
0%{opacity:1}
40%{opacity:1}
100%{width:100%;height:100%;opacity:0}
}
@keyframes circle {
0%{opacity:1}
40%{opacity:1}
100%{width:100%;height:100%;opacity:0}
}
@-webkit-keyframes shine {100%{left:125%}
}
@keyframes shine {100%{left:125%}
}
.services-style-current-theme .topper-service .thumb .service-icon{position:absolute;right:10%;background:#fdb510;bottom:0;padding:25px;border-radius:50%;transition:all .4s ease-in-out}
.services-style-current-theme .topper-service .thumb .service-icon img{width:75px;transform:scale(1);transition:all .4s ease-in-out}
.services-style-current-theme .topper-service .thumb img{     margin: 0 auto 0px auto;overflow:hidden;z-index:2;object-fit: fill;background: #f8f8f8;width: 150px;height: 150px;border: dashed 2px #e8ba21;}
.services-style-current-theme .topper-service .details .topper-info-list li{list-style:none;display:inline-block;margin-right:10px;margin-left:10px;margin-bottom:10px;position:relative;color: #fff;}
.services-style-current-theme .topper-service .thumb::before{position:absolute;top:0;left:-75%;z-index:2;display:block;content:'';width:50%;height:100%;
background:-webkit-linear-gradient(left,rgba(255,255,255,0) 0%,rgba(255,255,255,0.3) 100%);background:linear-gradient(to right,rgba(255,255,255,0) 0%,rgba(255,255,255,0.3) 100%);
-webkit-transform:skewX(-25deg);transform:skewX(-25deg)}
.text-theme-colored3{color: #ffc700!important;}
.font-weight-800{font-weight:800!important}
.tm-animation-floating{-webkit-animation-name:tm-animation-floating;-webkit-animation-duration:3s;-webkit-animation-iteration-count:infinite;
-webkit-animation-timing-function:ease-in-out;-moz-animation-name:Floating;-moz-animation-duration:3s;
-moz-animation-iteration-count:infinite;-moz-animation-timing-function:ease-in-out}
.title{color: #ffffff;font-size: 17px;text-align: center;line-height: 28px;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;}
.services-style-current-theme:hover .topper-service .thumb::before{-webkit-animation:shine .85s;animation:shine .85s}
.services-style-current-theme:hover .topper-service .thumb .service-icon{transform:rotateY(180deg)}
.services-style-current-theme:hover .topper-service .thumb::before{-webkit-animation:shine .85s;animation:shine .85s}
.bg-theme-colored3{background-color:#f33b10!important}
.bg-theme-colored4{background-color:#02b890!important}
.tm-floating-objects > span{position:absolute;width:100px;height:100px;z-index:0;background-repeat:no-repeat;background-position:center;opacity: 0.8 !important;height: 210px;width: 210px;}
.tm-animation-floating{-webkit-animation-name:tm-animation-floating;-webkit-animation-duration:3s;-webkit-animation-iteration-count:infinite;
-webkit-animation-timing-function:ease-in-out;-moz-animation-name:Floating;-moz-animation-duration:3s;-moz-animation-iteration-count:infinite;-moz-animation-timing-function:ease-in-out}
@-webkit-keyframes tm-animation-floating {
from{-webkit-transform:translate(0,0px)}
65%{-webkit-transform:translate(0,15px)}
to{-webkit-transform:translate(0,0px)}
}
@-moz-keyframes tm-animation-floating {
from{-moz-transform:translate(0,0px)}
65%{-moz-transform:translate(0,15px)}
to{-moz-transform:translate(0,0px)}
}

.link-more {display: inline-block;background-color: transparent;outline: none;border: 0;padding: 12px 0;font-size: 13px;position: relative;float: right;color: #bd2050;font-weight: bold;letter-spacing: 1px;text-transform: uppercase;}
.link-more:before {width: 100%}
.link-more:before,.link-more:after {content: "";position: absolute;left: 0;bottom: 0;background-color: #bd2050;height: 1px}
.link-more:after {width: 30%;transition: all .3s;background-color: #000000!important;}
.head2 {margin-bottom: 0px;letter-spacing: 0px;font-size: 22px;font-weight: 600;line-height: 48px;margin-top: 5px;color: #000000;text-transform: uppercase;} 
.text-btn {display: flex;justify-content: space-around;padding-bottom: 30px;padding-top: 30px;} 
.link-more:hover{color: #130F40;}
section.bg11 {background: #edf4f6;height: 600px;}

.tp-counter-area{padding-top: 80px;padding-bottom: 80px;}

.tp-counter-overlay {
    background: #023a51;
}
.p-relative {
    position: relative;
}

.tp-counter-wrapper {
    border: 1px dashed rgba(255, 255, 255, 0.25);
    padding: 25px 20px 15px 34px;
    position: relative;
    justify-content: space-between;
    z-index: 1;
    transition: all 0.3s ease-in-out;
    align-items: center;
}
.mb-30 {
    margin-bottom: 30px;
}

.tp-counter-title {
    font-weight: 600;
    font-size: 20px;
    color: #ffc700;
    transition: all 0.3s ease-in-out;
    margin-bottom: 0;
    letter-spacing: 2px;
    line-height: 31px;
}


.tp-counter-thumb i {
    font-size: 58px;
    background-image: linear-gradient(180deg, #FFF 0%, rgba(13, 28, 55, 0) 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    display: inline-block;
    margin-left: 15px;
}

.tp-counter-wrapper::after {
    position: absolute;
    content: "";
    width: 40%;
    height: 102%;
    left: -2px;
    top: -1px;
    background: linear-gradient(90deg, #2144D8 0%, rgba(13, 28, 55, 0) 100%);
    transition: all 0.3s ease-in;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
}
.tp-counter-wrapper:hover::after {
    width: 100%;
    visibility: visible;
    opacity: 1;
}
.tp-counter-wrapper::after {
    position: absolute;
    content: "";
    width: 40%;
    height: 102%;
    left: -2px;
    top: -1px;
    background: linear-gradient(90deg, #20bd8d 0%, rgba(13, 28, 55, 0) 100%);
    transition: all 0.3s ease-in;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
}
.tp-counter-content p {
    font-weight: 600;
    font-size: 15px;
    line-height: 29px;
    color: #ffffff;
    letter-spacing: 1px;
    text-align: left;
}
.tp-counter-shape .shape-1 {
    position: absolute;
    top: 0;
    left: -15px;
}
.tp-counter-shape .shape-2 {
    position: absolute;
    bottom: -80%;
    right: 0px;
}
.tp-counter-wrapper:hover .tp-counter-content h4 {
    color: #ffffff;
}

.slider-rightmenu {
    position: absolute;
    right: 0;
    top: 450px;
    z-index: 999;
    text-align: center;
    height: auto;
    width: auto;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    overflow: hidden;
}

.slider-rightmenu ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.slider-rightmenu li.group {
    margin: 50px -70px 40px 0px;
    position: relative;
    display: inline-block;
    text-align: center;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    z-index: 1;
}
.magnify-title {
    display: none;
}
.slider-rightmenu A.links-urllink {
    display: block;
    background-color: #113683;
    padding: 17px;
    margin: 15px;
    line-height: 1;
    font-weight: 900;
    letter-spacing: .1em;
    text-transform: uppercase;
    color: #fff;
    font-size: .875em;
    box-sizing: border-box;
    text-align: center;
    background: linear-gradient( to right, #cd1616, #fcb045);
}

.slider-rightmenu A.links-urllink:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.slider-rightmenu a.custom-link-photo:before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 0;
    -webkit-transform: translate(-50%,1000%);
    -ms-transform: translate(-50%,1000%);
    transform: translate(-50%,1000%);
    width: 36px;
    height: 36px;
    background-color: #113683;
    border-radius: 50%;
    z-index: -1;
    -webkit-transition: -webkit-transform .6s ease;
    transition: -webkit-transform .6s ease;
    -o-transition: transform .6s ease;
    transition: transform .6s ease;
    transition: transform .6s ease, -webkit-transform .6s ease;
    background: linear-gradient( to right, #cd1616, #fcb045);
}

.slider-rightmenu .link-image {
    float: none;
    margin: 0 0 1.75em;
    max-height: 17px;
    max-width: 17px;
    -webkit-transform: rotate(90deg) translateX(1000%);
    -ms-transform: rotate(90deg) translateX(1000%);
    transform: rotate(90deg) translateX(1000%);
    -webkit-transition: -webkit-transform .6s ease;
    transition: transform .6s ease, -webkit-transform .6s ease;
    -o-transition: transform .6s ease;
}

.slider-rightmenu li.group:nth-child(2) A.links-urllink {
    background-color: #0048b2;
}

.slider-rightmenu li.group:nth-child(3) A.links-urllink {
    background-color: #bf890d;
}

.slider-rightmenu li.group:hover a.custom-link-photo:before {
    -webkit-transform: translate(-50%,0);
    -ms-transform: translate(-50%,0);
    transform: translate(-50%,0);
}

.slider-rightmenu li.group:hover .link-image {
    -webkit-transform: rotate(90deg) translateX(50%);
    -ms-transform: rotate(90deg) translateX(50%);
    transform: rotate(90deg) translateX(50%);
}

.slider-rightmenu li.group:nth-child(3) A.links-urllink, .slider-rightmenu li.group:nth-child(3) a.custom-link-photo:before {
    background-color: #5d7c18;
    background: linear-gradient( to right, #6f7280, #2a2c3c);
}

.slider-rightmenu li.group:nth-child(2) A.links-urllink, .slider-rightmenu li.group:nth-child(2) a.custom-link-photo:before {
    background-color: #0f9245;
    background: linear-gradient( to right, #181827, #833ab4);
}

.page-header-bg { background-image: url('https://webapi.entab.info/api/image/LCSD/public/Images/page-header-bg.gif'); } 
.page-header { position: relative; display: block; padding: 117px 0 113px; background-color: #f2f5f9; z-index: -1; } 
.page-header-bg { position: absolute; top: 0; right: 0; bottom: 0; width: 1221px; background-repeat: no-repeat; background-size: cover; background-position: center; z-index: -1; } 
.page-header-bg:before {    position: absolute;
    top: 0;
    left: -1px;
    bottom: 0;
    width: 250px;
    content: "";
    background-image: linear-gradient(90deg, #f3f6f8 0%, rgba(243, 246, 248, 0) 98%)
}
.page-header-shape-1 { position: absolute; top: 0; left: 0; bottom: 0; mix-blend-mode: luminosity; opacity: .20; } 
.page-header-shape-1 img { width: auto; } 
.page-header__inner { position: relative; display: block; z-index: 15; } 
.page-header__inner h2 {font-size: 30px;color: #16243d;font-weight: 600;line-height: 52px;letter-spacing: 3px;text-transform: capitalize;} 
.thm-breadcrumb { position: relative; display: block; } 
.thm-breadcrumb li { position: relative; display: inline-block; color: #696e77; font-size: 14px; font-weight: 400; text-transform: uppercase; letter-spacing: 0.1em; -webkit-transition: all 500ms ease; transition: all 500ms ease; } 
.thm-breadcrumb li+li { margin-left: 5px; } 
.thm-breadcrumb li a { position: relative; display: inline-block; color:#696e77; font-size: 14px; font-weight: 400; -webkit-transition: all 500ms ease; transition: all 500ms ease; } 
.thm-breadcrumb li:hover a {color: #ffb400;} 
.thm-breadcrumb li:not(:last-child)::after { content: '/'; color: #696e77; font-size: 14px; margin-left: 10px; }
/* ------------------------------------
tc
---------------------------------------*/
.tcblock { flex-wrap: wrap; display: flex; flex-direction: row; justify-content: space-between; width: 100%; } 
 .tc { width: 49%; margin-bottom: 30px; } 
.tcblock iframe { width: 49%; } 
.tc label { color: #000; width: 100%; margin-bottom: 0; } 
.tc label p { margin-bottom: 0px; } 
.tc .form-group { margin-bottom: 20px; } 
.form-control:focus { box-shadow: none; border: 1px solid #ccc; } 
a.viewdetailsbtn { color: #fff; font-size: 14px; } 
.viewdetailsbtn {background: #20bd8d;padding: 10px;text-decoration: none;color: #fff;border-radius: 5px;margin-bottom: 20px;border: none;}
.viewdetailsbtn:hover {background: rgb(18 192 193) linear-gradient(160deg,#079899 0%,#12c0c1 50%,#0b8889 100%) repeat scroll 0 0;}

.years{ min-height: 250px; position: relative; } 
.showYear{ display: flex; }
.month-selection select { padding: 10px 8px; border: 0px; width: 95%; margin-left: 10px; min-width: 150px; padding: 5px 10px; border: 1px solid #ccc; } 
.searchBox input { padding: 2px 4px; background: #ffffff; width: 100%; padding-right: 0px; border: solid 1px #c3c3c3; } 
.tabs-dynamic { margin-bottom: 40px; background: #ededed; padding: 20px 10px; display: flex; flex-direction: row; justify-content: center; align-items: center; border: 1px solid #ccc; width: 100%; margin: 0 auto; margin-bottom: 40px; background: #f1f1f1; padding: 10px 0px; } 
.book-search{background: #f5f5f5;padding: 20px;margin-bottom: 20px;height: auto;position: relative;display: flex;align-items: center;justify-content: end;}
.book-search img{width: 80px;position: absolute;top: -20px;left: 0px;}
.book-search input{color: #000;font-size: 14px;line-height: 28px;padding-left: 10px;width: 300px;}
.book-search input:focus-visible{outline: none;}
.book-search input:focus{border:none;}
.book-search .searchBox{display: flex;width: max-content;align-items: center;float: right;margin-left: 30px;}
.book-search label{margin:0px;width: 90px;}
.booklist{margin:0px;}
.booklist ul li{margin:0px;}
.booklist ul{display:flex;}
.clr{clear:both;}
.count-val p {margin: 0;padding: 0px;} .no-data:before { content: "No Data Found"; position: absolute; transform: translate(-50%, -50%); left: 50%; font-size: 70px; top: 50%; } 
.newsCount, .eventCount, .galleryCount { position: relative; background: #fff; } 
.newsCount:before, .event-blk:before, .galleryCount:before { content: ""; width: 100%; height: 100%; position: absolute; background: #fff; left: 0; } 
.event-blk { position: relative; margin-bottom: 15px; box-shadow: 0px 0px 6px 0 #ddd; } 
.next { clear: both; background: #0f4e8c; margin: auto; display: block; color: #fff; padding: 5px 20px; width: max-content; cursor: pointer; } 

.event-flex { width: 100%; display: flex; justify-content: flex-start; flex-direction: column; } 
.inr-event-blk { display: flex; flex-direction: row; position: relative; height: 100%; border-radius: 5px; background-color: #fff; box-shadow: 0 0 20px 0px rgb(0 0 0 / 25%); } 
.inr-event-img { min-width: 250px; width: 250px; height: auto; min-height: 160px; margin-right: 5px; overflow: hidden; will-change: transform; } 
.inr-event-img img { width: 250px; height: 100%; transition: all 1.5s cubic-bezier(0, 0, .2, 1); will-change: transfrom; object-fit: contain; background: #f1f1f1; padding-left: 0px; } 
.inr-event-content { overflow: hidden; padding: 10px 15px; word-break: break-word; position: relative; } 
.inr-event-content:before { display: none; position: absolute; content: ""; height: 100%; width: 98%; background: linear-gradient(45deg, #eddfeb, #fff4da); transform: skewX(70deg); top: 0; left: 0; } 
.inr-event-content h6.date { font-size: 13px; line-height: 22px; color: #ea1f17; margin-bottom: 8px!important; font-weight: 600; position: relative; margin: 0; } 
.inr-event-content h3 { color: #232323; font-size: 16px; line-height: 28px; display: block; margin-bottom: 5px!important; font-weight: 600; position: relative; margin: 0; text-transform: uppercase; } 
.inr-event-content p { line-height: 28px!important; color: #383838!important; padding: 0; margin: 0; position: relative; margin-bottom: 15px!important; } 
.inr-event-content h6.loc { font-size: 13px; line-height: 22px; font-weight: 400; color: #000000; margin-bottom: 10px!important; position: relative; margin: 0; } 
.inr-event-content h6.loc a { color: #6e6e6e; } 
.inr-event-content h6.loc span { margin-left: 20px; } 
.inr-event-content h6.loc span i { color: #000000; } 
.inr-event-blk:hover .inr-event-img img { opacity: 0.8; -webkit-transform: scale3d(1.1, 1.1, 1.1); transform: scale3d(1.2, 1.2, 1.2); } 
.inr-news-blk h6.date {font-size: 12px;text-align: center;font-weight: 600;margin: 0px 0px;color: #ffffff;height: 100%;background: linear-gradient( to right, #6f7280, #022939);padding: 5px 5px;width: fit-content;letter-spacing: 1px;} 
.inr-news-blk { margin-bottom: 20px; justify-content: flex-start; align-items: center; box-shadow: 0 0 20px 0px rgb(0 0 0 / 25%); } 
.next {clear: both;margin: auto;display: block;color: #fff;padding: 5px 20px;width: max-content;cursor: pointer;background: linear-gradient( to right, #cd1616, #fcb045);margin-top: 30px;} 
.next:hover {background: linear-gradient( to right,  #fcb045, #cd1616);}
.inr-news-content { padding: 10px 15px; word-break: break-word; position: relative; } 
.inr-news-content h3 {color: #232323;font-size: 18px;line-height: 28px;display: block;margin-bottom: 5px!important;font-weight: 500;position: relative;margin: 0;text-transform: uppercase;} 
.inr-news-content p { line-height: 27px!important; color: #383838!important; padding: 0; margin: 0; position: relative; margin-bottom: 15px!important; } 
.inr-news-content h6.loc { font-size: 13px; line-height: 22px; font-weight: 400; color: #6e6e6e; margin-bottom: 8px!important; position: relative; margin: 0; } 
.inr-news-content h6.loc:hover { color: #1daea2; }

.innerbody{min-height:400px;padding-top: 90px;padding-bottom: 5px;}
.innerbody_left { position: relative; display: block; margin-right: 100px; } 
.innerbody_left__img-box { position: relative; display: block; } 
.innerbody_left__img { position: relative; display: block; border-radius: 10px; } 
.innerbody_left .innerbody_left__img>img { width: 100%; border-radius: 10px; } 
.innerbody_left__img-two { position: absolute; right: -130px; bottom: -145px; border-radius: 10px; } 
.innerbody_left .innerbody_left__img-two>img { width: auto; border-radius: 10px; } 
.innerbody__right { position: relative; display: block; margin-left: 70px; } 
.innerbody__right .section-title { margin-bottom: 30px; } 
.innerbody__text { font-size: 18px; line-height: 34px; letter-spacing: -0.02em; } 
.section-title { position: relative; display: block; margin-top: -7px; margin-bottom: 48px; } 
.section-sub-title-box { position: relative; display: inline-block; margin-left: 40px; }
.section-sub-title { font-size: 16px; color: #16243d; font-weight: 700; line-height: 54px; text-transform: uppercase; letter-spacing: 0.1em; margin-bottom: 0px; padding: 0px; } 
.section-title-shape-1 { position: absolute; top: -1px; left: -40px; } 
.section-title-shape-2 { position: absolute; top: -1px; right: -39px; } 
.section-title-shape-2 img { width: auto; } 
.section-title__title { margin: 0; color: #16243d; font-size: 30px; line-height: 42px; font-weight: 700; letter-spacing: -0.02em; } 

.wt-team-1 .team-name a:hover{ color:#ff5e15; } 
.wt-team-1 .team-position{ font-size: 24px; line-height: 20px; display: block; } 
.wt-team-1 .wt-media{ position:relative; overflow:hidden; text-align: center; } 
.wt-team-1 .wt-media img{ width:100%; padding-bottom:20px;} 
.wt-team-1 .team-social-center{position: absolute;top: 100%;right: 0px;bottom: 0px;width:100%;background-color: rgba(0, 0, 0, 0.8);border: 7px solid #ff2b6c;-webkit-transition: all 0.5s linear;-moz-transition: all 0.5s linear;-ms-transition: all 0.5s linear;-o-transition: all 0.5s linear;transition: all 0.5s linear;overflow:hidden;} 
.wt-team-1 .team-social-center ul{ list-style: none; position: absolute; top:50%; left:50%; overflow:hidden; text-align: left; transform:translateX(-50%); -webkit-transform:translateX(-50%); } 
.wt-team-1 .team-social-center ul li{ display:inline-block; position:relative; -webkit-transition: all 0.5s linear; -moz-transition: all 0.5s linear; -ms-transition: all 0.5s linear; -o-transition: all 0.5s linear; transition: all 0.5s linear; } 
.wt-team-1 .team-social-center ul li a:hover{color: #ff91b3;} 
.wt-team-1 .team-social-center ul li a{ color: #fff; font-size: 20px; padding: 5px 5px; display: inline-block; } 
.wt-team-1 .wt-info{ position: relative; } 
.wt-team-1:hover .team-social-center{ top:0px; }
.p-a30 {padding: 30px;}
.team-name a {color: #000000;font-size: 17px;text-transform: uppercase;}
.maintable img { background: #ccc; width: 75px; height: 75px; object-fit: contain; border: 1px solid #ccc; float: inherit !important; padding-left: 0px !important; }
.wt-team-1 {float: right;padding-left: 20px;width: 513px;}

.details img {margin-bottom: 0;}
p.details {padding: 0 0 10px 0;margin-bottom: 7px;}
.details a{color: #bd2050;text-decoration:none}
.guideheading {color: #332d20;font-size: 20px;font-weight: bold;padding: 0px;margin: 0px;padding-bottom: 15px;}
.assistance{background:rgba(68,23,20,0.06);padding:13px;border:solid 1px #ddd}
h4.sub_head1 {font-size: 17px;margin: 13px 0;color: #000000;font-weight: 400;}
#myImg,#myImg1,#myImg2{width:100%;border:5px solid rgba(158,158,158,0.1);background:#eaeaea;cursor:pointer;transition:.3s}
#myImg:hover{opacity:.7}
.animodal{display:none;position:fixed;z-index:99999999999;padding-top:100px;left:0;top:0;width:100%;height:100%;overflow:auto;background-color:#000;background-color:rgba(0,0,0,0.9)}
.animodal-content{margin:auto;display:block}
#anicaption,#anicaption2,#anicaption3{margin:auto;display:block;text-align:center;color:#fff;padding:10px 0;height:150px;font-size:24px;text-transform:capitalize}
.animodal-content,#anicaption{-webkit-animation-name:zoom;-webkit-animation-duration:.6s;animation-name:zoom;animation-duration:.6s}
@-webkit-keyframes zoom {
from{-webkit-transform:scale(0)}
to{-webkit-transform:scale(1)}
}
@keyframes zoom {
from{transform:scale(0)}
to{transform:scale(1)}
}
.close{cursor:pointer;outline:none;position:absolute;top:15px;right:35px;font-size:30px;font-weight:700;transition:.3s;opacity:.5;color:#fff}
.zoom-controls {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 10px;
  }
  
  .zoom-btn {
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .zoom-btn:hover {
    background: rgba(0, 0, 0, 0.8);
  }
  .gal_new .gal-content > a {
    font-size: 14px;
    color: #ffffff;
    display: block;
    margin: 0;
    position: absolute;
    top: 0;
    background: linear-gradient(45deg, #d73520, #fbae44);
    left: 0;
    padding: 2px 7px;
}
a.downloadPic {
    background: linear-gradient( to right,  #cd1616, #fcb045);
    padding: 5px 18px;
    color: #fff;
    border-radius: 5px;
    margin-left: auto;
    display: block;
    width: max-content;
    max-width: 100%;
    text-transform: uppercase;
    letter-spacing: 0px;
}
@media only screen and (max-width: 700px) {
.modal-content{width:100%;height:auto}
.animodal-content,#anicaption{width:100%}
}


.gal_new {padding-bottom: 20px;}
.block { display: flex; justify-content: center; flex-wrap: wrap; align-items: center; } 
.block>div { position: relative; width: 24%; margin-left: 12px; margin-bottom: 20px; } 
.block img { width: 100%; height: 300px; object-fit: contain; border: 1px solid #bdbdbd; padding: 4px; transition: 0.5s; margin-bottom: 20px; background: #ededed; } 
.block img:hover { filter: grayscale(100%); } 
.block>div h5 { left: 50%; font-size: 20px; background: #ffdc73; padding: 5px 25px; position: absolute; bottom: 15px; transform: translate(-50%, 50%); width: max-content; color: black; } 
.gal_new article { position: relative; box-shadow: 0px 0px 9px 0px #b3b3b3; border-radius: 5px; } 
.gal_new .total-img { position: absolute; right: 0; top: 0; color: #000; background: #e1e1e1b5; } 
.gal_new .total-img {display: flex;padding: 0px 8px;border-radius: 10px;} 
.gal_new .total-img p {margin-bottom: 0;margin-left: 3px;background: linear-gradient( to right,  #fcb045, #cd1616);width: 20px;height: 20px;display: flex;align-items: center;justify-content: center;color: #fff!important;border-radius: 50%;font-size: 12px!important;position: absolute;left: 0;bottom: 2px;padding: 0PX;} 
.gal_new .total-img i { font-size: 25px; } 
.gal_new .gal-content { padding: 15px 15px; } 
h4.catgry-name {color: #232323;font-size: 17px;line-height: 25px;border-bottom: 1px dotted #9db4b2;margin: 0!important;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-transform: uppercase;font-weight: 500;min-height:50px;} 
.gal-content p {overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;color: black!important;margin: 10px 0;line-height: 26px!important;text-align: left!important;height: 3.05em;} 
.gal_new article img { width: 100%; height: 180px; object-fit: contain; background: #f1f1f1; margin: auto; display: block; float: inherit; padding-left: 0px; } 
.gal_new h6.date {font-size: 13px;line-height: 22px;font-weight: 400;color: #6e6e6e;margin-bottom: 8px!important;position: relative;margin-top: 10px;} 
.gal_new h6.date a { color: #ffffff; font-size: 13px;cursor: pointer;} 
.gal_new h6.date span {margin-left: 20px;float: right;background: linear-gradient( to right, #6f7280, #022939);padding: 0 10px;} 
.gal_new h6.date span i { color: #ffffff; } 
.discription h3 { text-align: center; color: #232323; font-size: 19px; text-transform: uppercase; font-weight: 600; } 
.discription p { text-align: center!important; }
.discription { margin-top: 10px; } 
.discription p span { font-weight: 600; font-size: 18px; } 
.zoompic img {width: 100%;height: 200px;object-fit: contain;object-position: center;min-height: 200px;max-height: 200px;margin: 0px auto 0px auto;display: block;border: 6px double #f440774f;padding: 0;background: #f1f1f1;} 
h6.date i {color: #bd2050;margin: 3px;font-size: 15px;vertical-align: initial;}
.flexbox-img { display: flex; justify-content: flex-start; align-items: center; flex-wrap: wrap; width: 100%; } 
.flexbox-img > div { width: 23%; margin-bottom: 35px; margin-left: 20px; } 
.flexbox-img img { height: 200px !important; object-fit: contain !important; object-position: center !important; min-height: 200px !important; max-height: 200px !important; margin: 0px auto 0px auto !important; display: block !important; border: 6px double #b4dcec !important; padding: 0 !important; background: #f1f1f1 !important; width: 100% !important; } 
.flexbox-v { display: flex; justify-content: flex-start; align-items: center; flex-wrap: wrap; width: 100%; } 
.flexbox-v > div { width: 23%; margin-bottom: 35px; margin-left: 20px; }
 .sub-gallery { flex-wrap: wrap; display: flex; align-items: center; justify-content: center; background: #f1f1f1; margin-bottom: 20px; } 
.sub-gallery > div { width: 25%; } 
.sub-gallery > div { color: #000; font-size: 16px; padding: 10px; border-right: 1px solid #bbbbbb; } 
.sub-gallery > div:nth-child(04) { border-right: unset; } 
.shrink a { color: #000; } 

.head1{font-size: 24px;font-weight: normal;margin-top: 0px;color: #bd2050;text-transform: uppercase;margin-bottom: 15px; padding-top: 10px;}
.head2{font-size: 23px;font-weight: 600;margin-top: 0px;color: #000000;text-transform: uppercase;/* margin-bottom: 15px; */}
.head3{font-size:18px;margin-top: 10px;color: #000000;margin-bottom: 10px;}

::marker {color: #bd2050;}
ul.lilist {padding-left: 15px;}
ul.lilist li {
    list-style: disc;
}
.rightimg {float: right;}
.imgspace {margin-bottom: 30px;width: 100%}
.video-gallery a {align-items: center;} 
.video-gallery a img {width: 100%;height: 300px;object-fit: contain;/* background: #f1f1f1; */margin-bottom: 20px;} 
.video-gallery a h3 {font-size: 16px;padding-top: 0;padding-bottom: 0;font-weight: 600;text-transform: uppercase;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;color: black!important;margin: 5px 0;line-height: 26px!important;text-align: center;height: 3em;display: grid;} 
.video-gallery {padding: 10px;box-shadow: 0px 0px 5px 0px #ddd;margin-bottom: 20px;border-radius: 10px;} 
.innertable td { border: 1px solid #ccc; } 
.innertable tr th { background: #022939; border: 1px solid #ccc; color: #fff;font-weight: normal; } 
.innertable tr:nth-child(odd) { background: #0e7a4a1a; }
.left-align-images{float: right;margin-left: 15px;}
.right-align-images{float: left;margin-right: 15px;}
.innertopper p {padding-bottom: 0;}
.bd-blog a {color: #2d2c2c;}
.camptable a {text-align: center;display: block;color: #c4580b;}
.camptable {margin-top: 20px;}
.contact-page .bi{background:#dd4b26;padding: 14px;font-size: 15px;color: #fff;border-radius: 100%;margin-right: 4px;width: 40px;height: 40px;}
.contact-page .fa:hover{}
.contact-page a{color:#000;}
.contact-page p {margin-bottom: 27px;line-height: 40px;}
.condetails{display: flex;justify-content: space-between;flex-wrap: wrap;}
.align-images img {object-fit: contain;background: #f1f1f1;height: 250px;width: 100%;}
.align-images h6 {text-align: center;text-transform: uppercase;background: #e1e1e1;padding: 9px;}
tr{text-align: center;}
.yyy {float: left;margin: 10px auto;padding: 15px;width: 33.3%;}
.yyy img {height: 190px;}
.accordion-button{padding:8px;text-transform: uppercase;}
.card {margin-bottom:8px;}
.Principals h5{color: #bd2050;text-align: center;padding-top: 9px;font-size: 17px;line-height: 23px;}
.Principals h5 span{color: black;display:block;font-size: 14px;}
.head_girl { border: solid 0px #333; float: right; margin: 0 0px 5px 21px; border: solid 1px #333; } 
.btn_img img {padding: 0px 5px 0px;margin-top: 15px;width: 90%;background: transparent;}
.btn_img {display: flex;}
.offcanvas-body p a{ color:#000000}
.offcanvas-body p a:hover {color: #20bd8d;opacity: 1;margin-left: 5px;}
.btn_img a img:hover {transform: scale(1.1);filter: grayscale(100%);-webkit-filter: grayscale(100%);-moz-filter: grayscale(100%);transition: .3s linear;}
.erp_links {bottom: 80px;position: fixed;z-index: 99;width: 100px;right: 0px;}
.card-header{ padding:0px;}
.accordion-button:focus {box-shadow: none;}

.achievementimg.achievementmulti a { width: 30%; margin: 0px 10px 15px 10px; } 
.achievementimg.achievementmulti { width: 100%; display: flex; flex-wrap: wrap; flex-direction: row; justify-content: center; } 
.achievementdetails { flex-wrap: wrap; display: flex; justify-content: space-between; background: #d59e582b; padding: 10px; align-items: center; flex-direction: row; margin-bottom: 10px; border-radius: 3px; }
.achievementpgh { display: flex; } 
.achievementimg img {height: 250px;width: 100%;object-fit: contain;border-radius: 5px;background: #fff;margin-bottom: 15px;padding-left: 0px;border: dashed 1px #bd2050a1;} 
.achievementpgh { line-height: 30px; font-size: 16px; display: block; }

.rightimg {bottom: 0px;position: fixed;z-index: 99;width: 120px;}
.rightimg img {width: 147px;border: 5px solid #ffffff;box-shadow: 0px 4px 5px 0px #00000024;border-radius: 15px 0px 0px 0px;}
.card-body a{ color:#303131;}
.card-body a:hover{ color:#bd2050;}
.accordion-button{ font-weight:bold;}

.magazine img {/* margin: 0px auto 15px; *//* transition: transform .5s; *//* height: 300px; *//* border: 1px solid #e1e1e1; *//* background: #274c83; *//* padding: 10px; */}
.magazine img:hover {}
/* .d-flex.flipSelection .flip-block { 
    display: block; 
} */
.aboutsecdesc p {
    margin-bottom: 13px;
    line-height: 34px;
}
.magazine .magbox {padding: 20px;box-shadow: 0px 0px 5px 0px #ddd;border-radius: 10px;}
.magbox h5{font-size: 18px;text-align: center;margin-bottom: 15px;}
.nav-link {padding: 0px 10px;}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {color: #fff; background-color: #be1e37;}
.nav-pills .nav-link {background: 0 0; border: 0;border-radius: 0.25rem;border: solid 2px #f1f1f1;margin: 5px;color: #141414; font-weight: 600;}
.jpic-gal { margin-bottom: 20px;height: 320px;background: #ddd;object-fit: contain;width: 100%;border: 4px solid #ddd;}

.tablescroll {overflow-x: scroll;}
.table {max-width: 100%; margin-bottom: 20px; font-size: 15px; background: white;}
.table thead { background: #eeebff; color: black;}
.table tbody{color: black;}
td a {color: black;font-weight: bold;}
.bookWrapper {width: 950px;height: 640px;MARGIN: auto;margin-top: 350PX;} .bookBg {position: relative;/* background-color: #000; */width: 100%;height: 100%;border-radius: 12px;}
.pageBg {position: absolute;left: 100%;top: 50%;background-color: #e9e9e9;width: calc(100% - 20px);height: calc(100% - 20px);border-radius: 0px;CURSOR: POINTER;}
.pageWrapper { position: absolute; width: 50%; height: 100%; float: left; -webkit-font-smoothing: antialiased; }
.page { position: absolute; width: 100%; height: 100%; }
.pageFace {position: absolute;top: 0;width: 100%;height: 100%;overflow: hidden;text-align: center;}
.front {background: #FFF;}
.back {background: #FFF;}
.pageFoldRight {position: absolute;width: 0px;height: 0px;top: 0;right: 0;border-left-width: 1px;border-left-color: #DDDDDD;border-left-style: solid;border-bottom-width: 1px;border-bottom-color: #DDDDDD;border-bottom-style: solid;box-shadow: -5px 5px 10px #dddddd;}
.pageFoldLeft {position: absolute;width: 0px;height: 0px;top: 0;left: 0;border-right-width: 1px;border-right-color: #DDDDDD;border-right-style: solid;border-bottom-width: 1px;border-bottom-color: #DDDDDD;border-bottom-style: solid;box-shadow: 5px 5px 10px #dddddd;}
.open_popup{ background: #274c83; border:none; padding:5px 15px; color:#fff; } .open_popup:hover{ background: #be1e37;}
.popup_body{position:fixed;top:0;left:0;right:0;bottom:0;-webkit-transition: 300ms;-moz-transition: 300ms;-o-transition: 300ms;-ms-transition: 300ms;transition: 300ms;z-index: 9999;}
.popup_body_show{display:block;-webkit-transition: 300ms;-moz-transition: 300ms;-o-transition: 300ms;-ms-transition: 300ms;transition: 300ms;overflow: auto;}
.popup_back{ background: rgba(0, 0, 0, 0.8); position: fixed; left:0;right:0;top:0;bottom:0; } 
.popup_contain{ 
    /* background: #fff; */
    min-width: 300px;
    max-width: 100%;
    margin: 50px auto 50px;
    min-height: 150px;
    padding: 10px 20px;
    }
    .goog-te-gadget > div, .goog-te-gadget > div a {
        width: 100%;
        display: inline;
        padding: 7px;
        text-transform: uppercase;
        background: #fff;
        border-radius: 6px;
    }
    
    div#google_translate_element {
        min-height: 26px;
    }
    #google_translate_element table div, #google_translate_element table span {
        color: #000;
    }
    
    .skiptranslate.goog-te-gadget {padding: 5px;}
    
    div#google_translate_element {
        margin-left: auto;
        display: block;
        width: max-content;
        margin-right: 20px;
        margin-top: 7px;
        border-radius: 7px;
        overflow: hidden;
    }
.popup_close{ cursor: pointer;background:red; padding:3px 10px 3px; position: absolute; right:10px; top: 10px; color: #fff; border-radius: 50%; }
.popup_contain p{position: absolute;left: 20px;top: -12px;background: #fff;padding: 5px 10px;width: 180px;font-size: 14px;border-radius: 7px;}
.gray-bg{background: #505050;margin:0px;height: 175px;width: 96%;position: absolute;bottom: 10px;z-index: 9;}
.maximspic>div {
    width: calc(34% - 30px );
}

.maximspic > div img {
    height: 100%;
    min-height: 280px;
    max-height: 280px;
}
.mfp-title {
    padding: 0 10px 10px 10px;
    letter-spacing: 0.3px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    text-align: justify;
    line-height: 29px;
    font-family:  Roboto , sans-serif;
    margin-bottom: 0px;
}
.mfp-counter:before {
    position: absolute;
    content: "Slide";
    width: 100%;
    height: 100%;
    top: -1px;
    left: -40px;
    color: #fff;
    font-size: 14px;
}
.flexbox-v.zoompic.maximspic.zoom-gallery {
    justify-content: center;
}
    .magnific-img img {
    width: 100%;
    height: auto;
}
.mfp-bottom-bar,*{
   font-family: Abel, sans-serif;
}
.magnific-img {
    display: inline-block;
    width: 32.3%;
}
a.image-popup-vertical-fit {
    cursor: -webkit-zoom-in;
}
.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  /* ideally, transition speed should match zoom duration */
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
    opacity: 1;
}
.mfp-with-zoom.mfp-ready.mfp-bg {
    opacity: 0.98;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}
.mfp-arrow-left:before {
    border-right: none !important;
}
.mfp-arrow-right:before {
    border-left: none !important;
}
button.mfp-arrow, .mfp-counter {
    opacity: 0 !important;
    transition: opacity 200ms ease-in, opacity 2000ms ease-out;
}
.mfp-container:hover button.mfp-arrow, .mfp-container:hover .mfp-counter{
    opacity: 1 !important;
}


/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #CCC; }
    .mfp-preloader a:hover {
      color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }
  .mfp-close:hover,
  .mfp-close:focus {
    opacity: 1; }
  .mfp-close:active {
    top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: -30px;
  right: 10px;
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
  }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover,
  .mfp-arrow:focus {
    opacity: 1; }
  .mfp-arrow:before,
  .mfp-arrow:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px; }
  .mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px; }
  .mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }

  .mfp-figure:after {
    content: "";
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
  margin-top: 0;
  }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }


  
.event-cal {
    margin-top: 50px;
    border: 1px solid #e0e4e8;
    border-radius: 5px;
}
.header-text h3 {
    color: #333;
    margin-left: 0px;
    font-family: 'Poppins', savbps-serif;
    font-size: 16px;
    /* background: #c95404; */
    text-align: center;
    padding: 20px 0px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 0px #c10005 solid;
}

.header-text h5 {
    display: none;
    font-size: 16px;
    text-align: center;
    color: #333;
    margin: 10px;
}
.calendar-header {
    background:#f1f1f1;
    height: 100%;
    color: #fff !important;
    font-family: 'Roboto',sans-serif;
    font-weight: 300;
    position: relative;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.calendar-header i {
    font-size: 47px !important;
    padding: 0px 10px;
}

.calendar-footer {
    font-family: 'Roboto',sans-serif;
    font-weight: 300;
    text-align: center;
    background: #e4e4e4;
    position: relative;
    overflow: hidden;
    padding: 8px 15px;
}

.calendar-footer h4 {
    margin: 20px auto;
}

.calendar-footer p {
    line-height: 19px;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
    margin-bottom: 4px;
    text-align: left;
}

.blue {
    color: #080e42;
    font-weight: bold;
}

.sidebar-title {
    padding: 10px;
    display: none;
}

.calendar-content {
    background-color:#f1f1f1;
    padding: 35px;
    overflow: hidden;
}
.calendar-wrapper.z-depth-2 {
    padding: 5px;
}
.event-mark {
    width: 5px;
    height: 5px;
    background-color: teal;
    border-radius: 100px;
    position: absolute;
    left: 46%;
    top: 70%;
}

.calendar-footer {
    font-family: 'Roboto',sans-serif;
    font-weight: 300;
    text-align: center;
    background: #F6F9FEAB;
    position: relative;
    overflow: hidden;
    padding: 27px 15px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    /* border: 1px solid #e0e4e8; */
}

.calendar-footer h4 {
    margin: 20px auto;
}

.calendar-footer p {
    line-height: 19px;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
    margin-bottom: 4px;
}

.eventlist p {
    margin: 0px 9px;
}

.eventlist {
    overflow-y: scroll;
    height: 70px;
    display: flex;
    flex-wrap: wrap;
}
.sidebar-wrapper {
    color: #fff;
    background-color: #5a649c !important;
    font-family: 'Roboto',sans-serif;
    font-weight: 300;
    padding: 0 0 20px;
}

.eventCard {
    background-color: #fff;
    color: #000;
    padding: 12px 24px;
    border-bottom: 1px solid #E5E5E5;
    white-space: nowrap;
    position: relative;
    animation: slideInDown .5s;
}

.eventCard-header {
    font-weight: 700;
}

.eventCard-description {
    color: grey;
}

.eventCard-mark-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 60px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 25%, white 100%);
}

.eventCard-mark {
    width: 8px;
    height: 8px;
    background-color: #b39ddb;
    border-radius: 100px;
    position: absolute;
    left: 50%;
    top: 45%;
}

#table-body .row .col {
    padding: .6rem;
    width: 14%;
}

#table-body .row {
    margin-bottom: 0;
}

#calendar-table {
    text-align: center;
}

.prev-button {
    position: absolute;
    cursor: pointer;
    left: 0;
    color: #333 !important;
}

.prev-button i {
    font-size: 4em;
}

.next-button {
    position: absolute;
    cursor: pointer;
    right: 0;
    color: #333 !important;
}

.next-button i {
    font-size: 4em;
}
.red{color: #c33337;}
.eventlist p span{color: #c33337;font-weight:bold;}
.eventlist p span.black{ color:black;font-weight:bold;}

div#table-header {
    font-size: 14px;
    font-weight: bold;
    color: black;
}
button.next-button ,button.prev-button {
    background: transparent;
    border: 0;
}
.col-md-7.kinderdiv {
    margin-left: 100px;
}
div#table-header .col {
    width: 13%;
}
div#table-body {
    justify-content: flex-start !important;
}
#table-body .col {
    min-width: 14%;
    padding: 12px 0px;
    text-align: center;
    max-width: 13%;
}
.fullwdh {
    padding: 0px 130px;
}
.erpbox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}

.erpboxdesc {
    width: 72%;
}

.erpboximg {
    width: 26%;
}
.erpboxdescpopup {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.erppopup {
    width: 30%;
    margin: 10px;
}

.imgheading a {
    color: #20488a;
    text-align: center;
    width: 100%;
    display: block;
    font-size: 20px;
    padding: 10px 0;
}

.erppopup .modal-body h5 {
    color: #000;
    text-align: center;
    padding-top: 20px;
}
#myImg,
#myImg1,
#myImg2 {
    width: 100%;
    border: 5px solid rgb(191 185 188);
    background: #cbcaca;
    cursor: pointer;
    transition: .3s;
}
.homeslider {
    position: relative;
    z-index: 1;
}
.accordion-button:not(.collapsed) {
    background-color: #e7f1ff !important;
}
.achievementacc .accordion-item {
    border: 0px;
}
.accordion-button {
    font-size: 15px;
    color: #706a6a !important;
}
.chooseyear { margin-bottom: 40px;}
#chooseddlYear{
    border: unset;
    color: #000;
    height: 30px;
    padding: 0px 0px 0px 4px;
    background: unset;
    font-weight: bold;
    margin-bottom: 15px;
    letter-spacing: 1px;
    width: 6.5em;
    font-size: 15px;
    float: right;
    margin-top: 30px;
    border-bottom: 1px solid grey;
    }

#chooseddlYear option { color: black;}
.chooseyear  select{margin:0px;}
.chooseyear  select{width:100%;background: unset;border: unset;height:28px;color:#fff;margin-top:15px;border: unset;border-bottom: 1px solid grey;}
.clr{clear:both}


/* FLIP BOOK */
.flip-popup { 
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1000;
    justify-content: center;
    align-items: center;
    overflow: auto;
    padding: 20px;
  }
  
  .flip-popup.active {
    display: flex;
  }
  
  .closeme {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    font-size: 30px;
    color: #fff;
    background: red;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(45deg);
  }
  
  .control-btn {
    position: absolute;
    bottom: 20px;
    left: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .flipOpen {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .flipPageComponent {
    max-width: 90%;
    max-height: 90%;
    position: relative;
    overflow: hidden; /* Ensure content stays within the bounds */
    margin: auto;
  }
  
  .page img {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .flip-block img {
    width: 100%;
  }
  
  
  
  .stf__block > div img {
    width: 100%;
  }
  
  .flip-block {
    width: 33%;
  }

  .d-flex.flipSelection{
    margin-top: 40px;
  }
  .d-flex.flipSelection .tab-pane {
    display: flex;
}

.d-flex.flipSelection .tab-pane .flip-block.showflip {
    padding: 12px;
}
.flip-block h3 {
    text-align: center;
    margin-bottom: 20px;
}
  
.bg-color-span{
    background-color: rgba(0, 0, 0, 0);
} 

.footer-widget .card-body img {
    width: 110px;
    margin-top: 5px;
}

.footer-widget .card-body h4 {
    color: #fff;
}

.footer-widget .card-body h5 {
    font-size: 16px;
    color: #fff;
    font-weight: 300;
}
.footer-widget .card-body h5 a {
    color: #fff;
}
/* FLIP BOOK */


.magnify-modal {
    position: fixed !important;
    z-index: 999999999 !important;
    padding-top: 10px !important;
    left: 0 !important;
    top: 0 !important;
    width: 100% !important;
    height: 100% !important;
    overflow: auto !important;
    background-color: rgba(0, 0, 0, 0.8509803922) !important
  }
  
  .magnify-header .magnify-toolbar {
    float: right;
    position: relative;
    z-index: 9
  }
  
  .magnify-stage {
    position: absolute;
    top: 40px;
    right: 10px;
    bottom: 40px;
    left: 10px;
    z-index: 1;
    background: #0000000a;
    overflow: hidden
  }
  
  .magnify-image {
    position: relative;
    display: inline-block;
    border: 5px solid #fff
  }
  
  .magnify-image.image-ready {
    max-width: 100%;
    max-height: 100%
  }
  
  .magnify-footer {
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 40px;
    color: #fff;
    text-align: center
  }
  
  .magnify-footer .magnify-toolbar {
    display: inline-block
  }
  
  .magnify-button {
    display: inline-block;
    width: 40px;
    height: 40px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 10px;
    font-size: 16px;
    color: #fff;
    text-align: center;
    border-width: 0;
    border-radius: 0;
    cursor: pointer;
    outline: none;
    background: #022939;
  }
  
  .magnify-button:hover {
    color: #fff;
    background-color: #e00000
  }
  
  .magnify-button-close:hover {
    background-color: #e00000
  }
  
  .magnify-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.3);
    color: #333
  }
  
  .magnify-loader::before {
    content: '';
    display: inline-block;
    position: relative;
    width: 36px;
    height: 36px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-width: 5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(255, 255, 255, 0.5);
    border-radius: 100%;
    -webkit-animation: magnifyLoading 1s infinite linear;
    animation: magnifyLoading 1s infinite linear
  }
  
  .magnify-loader::after {
    content: '';
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle;
    overflow: hidden
  }
  
  @-webkit-keyframes magnifyLoading {
    0% {
        -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
        transform: rotateZ(0deg) translate3d(0, 0, 0)
    }
  
    100% {
        -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
        transform: rotateZ(360deg) translate3d(0, 0, 0)
    }
  }
  
  @keyframes magnifyLoading {
    0% {
        -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
        transform: rotateZ(0deg) translate3d(0, 0, 0)
    }
  
    100% {
        -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
        transform: rotateZ(360deg) translate3d(0, 0, 0)
    }
  }
/*--------------------------------------------------------------
# Media Quiry
--------------------------------------------------------------*/
@media (max-width: 1700px) {
    .tm-floating-objects > span {display: none;}
    #cssmenu > ul > li > a {padding: 17px 7px;letter-spacing: 1px;}
    }

    
    @media (max-width: 1600px) {
        .about-sec h2 span {
            display: block;
        }
        header {
            margin-right: 10px;
        }
        .container-fluid.activitesfulwdh {
            padding: 0px 50px 10px 190px;
        }
        .newssec-area marquee {
            height: 100px;
        }
        .fullwdh {
            padding: 0px 70px;
        }
        .kinderbox {
            margin-left: 110px;
        }
        .kinder {
            margin-left: 10px;
        }
        .about-sec__right {
            margin-left: 0;
        }
    #cssmenu > ul > li.has-sub > a{padding-right:10px}
    #cssmenu > ul > li > a{padding:15px 10px;font-size:13px;letter-spacing:0;margin-left:0}
    .header-menu{margin-left:20px;margin-right:10px}
    }
    
    

@media (max-width: 1400px) {
    .midsecbox1 {
        width: 45%;
    }
    .midsecbox2 {
        width: 10%;
    }
    .calendar-content {
        padding: 13px;

    }
    .kinderdiv .section-title {
        margin-bottom: 30px;
    }
    .fullwdh {
        padding: 0px 25px;
    }
    .about-sec {
        
    padding: 65px 0px;

        background: linear-gradient(50deg, white 65%, #ffce51 60.5%);
    }
    .about-sec__right {
        padding: 40px 30px ;
    }
    .aboutaudiobox button {
        font-size: 14px;
        margin-left: 5px;
        padding: 10px 10px;
    }
    .aboutaudio {
        padding: 10px;
        border-radius: 5px;
    }
    .fulwdh {
        padding: 30px 30px;
    }
    .fw-title .title {
        margin-bottom: 11px;
        font-size: 18px;
    }
    }
@media (max-width: 1399px) {
    .eventlist {
        overflow-y: scroll;
        height: 90px;
    }
    /*.flip-popup>.flipOpen {
    position: relative;
    margin-top: 50px;
}*/
.lcsd-choose-img {width: 445px;height: 445px;margin: 0 auto;    border-width: 5px;margin-bottom: 35px;}
}

@media (max-width: 1399px) 
{
    #cssmenu > ul > li > a {
    padding: 15px 5px;
}
    .maximspic > div img {
    min-height: 240px;
    max-height: 240px;
}
.bd-hero-social-wrapper {display: flex;gap: 17px;left: 30px;top: 348px;} 
.gal-big img{height:280px;}
.gal-small img, .kinder .gal-small img{height: 250px;}
}
@media (max-width: 1370px) {
    .newssec-area marquee {
        height: 230px;
    }
    .midsec {
        margin-top: -70px;
    }
    .thm-btn {
    padding: 15px 10px 15px;
}
    .flipOpen{
        margin-top: 0px;
        transform: scale(0.7);
        transform-origin: top
    }
    .flipOpen {
        margin-top: 0;
    }
    .highlights_sec p a {
        font-size: 12px;
    }
    .logo img {
        padding-top: 20px;
        width: 100%;
    }
#cssmenu > ul > li > a{       padding: 15px 10px;
    font-size: 13px;letter-spacing:0;margin-left:0}
    #cssmenu > ul > li.has-sub > a {
        padding-right: 5px;
    }
}

@media (max-width: 1319px){
#cssmenu > ul > li > a {
    padding: 15px 8px;
}
}
@media (max-width: 1280px){
#cssmenu > ul > li > a {
    padding: 15px 7px;
    font-size: 12px;
}
#cssmenu {
    width: 100%;
    margin-left: 25px;
}
}
@media screen and (max-width:1234px) {
    #cssmenu > ul > li > a{font-weight:400}
    #cssmenu{margin-top:0!important}
    nav{width:100%}
    #cssmenu{width:100%;z-index:9999}
    #cssmenu ul{width:100%;display:none;background: #bd2050;z-index:99;margin-top: 6px;}
    #cssmenu ul li{width:100%;border-top: 1px solid #e9e9e9ab;}
    #cssmenu ul li:hover{background: #5c08088a;}
    #cssmenu ul ul li,#cssmenu li:hover > ul > li{height:auto}
    #cssmenu ul li a,#cssmenu ul ul li a{width:100%;border-bottom:0}
    #cssmenu > ul > li{float:none}
    #cssmenu ul ul li a{padding-left:15px;border-top:1px solid #fff8f8}
    #cssmenu ul ul li{margin-left:0}
    #cssmenu ul ul ul li a{padding-left:35px}
    #cssmenu ul ul li a{color:#242424;background:none}
    #cssmenu ul ul li:hover > a,#cssmenu ul ul li.active > a{color:#fff}
    #cssmenu ul ul,#cssmenu ul ul ul{position:relative;left:0;width:100%;margin:0;text-align:left;justify-content:flex-start}
    #cssmenu > ul > li.has-sub > a:after,#cssmenu > ul > li.has-sub > a:before,#cssmenu ul ul > li.has-sub > a:after,#cssmenu ul ul > li.has-sub > a:before{display:none}
    #cssmenu #head-mobile{display:block;padding:17px;color:#ddd;font-size:12px;font-weight:700}
    .menubutton{width:55px;height:46px;position:absolute;cursor:pointer;z-index:9;background: #bd2050;margin-top: -39px;border-radius: 0px 0px 0px 33px;}
    .menubutton:after{position:absolute;top:22px;right:20px;display:block;height:8px;width:20px;border-top:2px solid #fff;border-bottom:2px solid #fff;content:''}
    .menubutton:before{-webkit-transition:all .3s ease;-ms-transition:all .3s ease;transition:all .3s ease;position:absolute;top:16px;right:20px;display:block;height:2px;width:20px;background:#fff;content:''}
    .menubutton.menu-opened:after{-webkit-transition:all .3s ease;-ms-transition:all .3s ease;transition:all .3s ease;top:23px;border:0;height:2px;width:19px;background:#fff;-webkit-transform:rotate(45deg);-moz-transform:rotate(45deg);-ms-transform:rotate(45deg);-o-transform:rotate(45deg);transform:rotate(45deg)}
    .menubutton.menu-opened:before{top:23px;background:#fff;width:19px;-webkit-transform:rotate(-45deg);-moz-transform:rotate(-45deg);-ms-transform:rotate(-45deg);-o-transform:rotate(-45deg);transform:rotate(-45deg)}
    #cssmenu .submenu-button{position:absolute;z-index:99;right:0;top:0;display:block;border-left: 1px solid #e9e9e9ab;height:44px;width:42px;cursor:pointer}
    #cssmenu .submenu-button.submenu-opened{background: #bd2050;}
    #cssmenu ul ul .submenu-button{height:34px;width:34px}
    #cssmenu .submenu-button:after{position:absolute;top:22px;right:19px;width:8px;height:2px;display:block;background:#ddd;content:''}
    #cssmenu ul ul .submenu-button:after{top:15px;right:13px}
    #cssmenu .submenu-button.submenu-opened:after{background:#fff}
    #cssmenu .submenu-button:before{position:absolute;top:19px;right:22px;display:block;width:2px;height:8px;background:#ddd;content:''}
    #cssmenu ul ul .submenu-button:before{top:12px;right:16px}
    #cssmenu .submenu-button.submenu-opened:before{display:none}
    #cssmenu ul ul ul li.active a{border-left:none}
    #cssmenu > ul > li.has-sub > ul > li.active > a,#cssmenu > ul ul > li.has-sub > ul > li.active > a{border-top:none}
    #cssmenu > ul > li{float:left;margin-left:0;margin-bottom:0}
    #cssmenu{height:46px;border-radius:0;position:inherit;width:100%;margin-top:0}
    #cssmenu > ul > li > a{color:#fff;padding:13px 9px 15px 8px}
    .no-pad-menu{padding-left:0;padding-right:0}
    #cssmenu{float:left;width: 100%;}
    #cssmenu > ul > li.has-sub > a:before{background:#fff}
    #cssmenu > ul > li.has-sub > a:after{background:#fff}
    #cssmenu > ul > li{float:left}
    #cssmenu > ul > li > a{padding:15px 0 15px 15px}
    #cssmenu ul ul li a{padding:12px;border-bottom:none;font-size:15px}
    #cssmenu > ul > li:hover > a,#cssmenu ul li.active a{color: #ffffff;}
    .right-btn-link {float: right;margin-top: -55px;}
    .header-menu.navigation {margin-left: 0px;margin-right: 0px;}
    }

@media (max-width: 1199.98px) 
{
    .erppopup {
        width: 45%;
    }
    .about-sec h2 span {
        display: inline-block;
    }
    .footer-area.footer-bg .fulwdh {
        background: #022939;
    }
    .footer-bg {
        background-size: contain;
    }
    .calendar-content {
        padding: 10px;
    }
    .kinder .project-item {
        width: 100%;
        margin-top: 0;
    }
    .actititle {
        width: 45%;
        margin-left: -130px;
    }
    .container-fluid.activitesfulwdh {
        padding: 0px 50px 10px 100px;
    }
    .newnews:after {
        background: linear-gradient(324deg, #a51e1e00 60%, #f3c143 55%);
    }
    .newnews:before {
        background: linear-gradient(270deg, #ffffff00 46%, #ffce51 30%);
    }
    .newnews {
        padding: 50px 20px;
    }
    .aboutsecdesc p {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
    }
    .aboutaudiobox {
        flex-wrap: wrap;
    }
    .aboutaudiobox audio {
        width: 100%;
    }
   .maximspic > div img {
    min-height: 200px;
    max-height: 200px;
} 
.aboutaudiobox button {
    width: 100%;
    margin-top: 20px;
}
.about-sec__left {max-width: 600px;margin: 0 auto 60px auto;}
.about-sec__right {margin-left:0px;}
.newsletter-bg img {display: none;}
.newsletter-inner {background: #119a70;border-radius: 20px;padding: 50px 30px 50px;}
.modal-dialog {min-width: 80%;margin-top: 35px;}
.bd-hero-social-wrapper {display: none;}  
.page-header-bg {width: 768px;}
.month-selection select { padding: 10px 8px; border: 0px; width: 100%; margin-left: 0px; min-width: 150px; padding: 7px 7px; border: 1px solid #ccc; margin-bottom: 5px; } 
.searchBox input { padding: 7px 7px 3px 7px; }
.innerbody_left {max-width: 600px;margin: 0 auto 205px;}
.innerbody__right {margin-left: 0;}
.flexbox-v > div {width: 31%;}
}
@media(max-width: 1000px){
       /*.flipPageComponent.stf__parent {
    transform: scale(1.5);
}*/
}
@media (max-width: 991.98px) 
{.online-reg{width:auto;}.online-reg ul{margin:0px;flex-wrap: wrap; justify-content: center; align-items: center; width: 99%;}.online-reg li{padding:0px 20px;margin-bottom:10px;}
    .logo img {
        width: 60%;
    }
    .footer-top {
        padding: 5px 0 25px;
    }
    
    .midsecboxsec {
        margin: 10px 10px;
    }
    .midsecbox2 {
        width: 100%;
        order: 1;
    }
    .midsec {
        margin-top: 0;
    }
    .midsecbox1 {
        width: 100%;
    }
    .kinder .project-item {
        width: 73%;
        margin-top: 80px;
        margin-left: 110px;
    }
    .kinder {
        background-size: 60% 80%;
    }
    .kidcorner .fulwdh {
        padding: 30px 5px;
    }
    .kinderbox {
        margin-left: 0;
    }
    .kidcorner {
        padding: 10px 10px 20px 0px;
    }
    .activitiesslide {
        width: 70%;
    }
    .actititle {
        width: 50%;
        margin-left: -110px;
    }
    .container-fluid.activitesfulwdh {
        padding: 0px 12px 10px 160px;
    }
    .newnews:before {
        background: linear-gradient(270deg, #ffffff00 0%, #ffce51 0%);
    }
    .newnews:after {
        background: linear-gradient(318deg, #a51e1e00 45%, #f3c143 35%);
    }
    .about-sec {
        background: linear-gradient(180deg, white 50%, #ffce51 50%);
    }
    .kids .page-header-bg { 
    background-position: right !important; 
}
    .maximspic > div img {
    min-height: 220px;
    max-height: 220px;
}
    #exampleModalToggle .modal-dialog {
    min-width: 700px;
}
    .thm-breadcrumb li {
    line-height: 24px;
}
    .page-header-bg {
    width: 100%;
}
.page-header-shape-1 {
    display: none;
}
.page-header-bg:before {
    width: 100%;
    background-image: -moz-linear-gradient(0deg, #f3f6f8 0%, rgba(243, 246, 248, 0) 100%);
    background-image: -webkit-linear-gradient(0deg, #f3f6f8cc 0%, #f3f6f800 100%);
    background-image: -ms-linear-gradient(0deg, #f3f6f8 0%, rgba(243, 246, 248, 0) 100%);
}
    .event-sec {
    background-color: #fff;
    z-index: inherit;
}
    .homenewsbg
    {
            background-color: #025f85;
    }
    .why-choose-three__left:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    border:0px solid #ccc;
    background-color: transparent;
    z-index: -1;
    /* transform: skewX(-24deg); */
}

    .why-choose-three__shape-4 img
    {
        display:none;
    }
    .aboutimg {
    clip-path: none;
    position: static;
    }
    /*.flip-popup>.flipOpen{
       max-height: 600px;
    overflow: hidden;
    }*/
    /*.stf__item{
        top:0 !important
    }*/
    /* .react-transform-component.transform-component-module_content__FBWxo {
    top: 50% !important;
    position: relative;
}
    .flipPageComponent.stf__parent {
    margin-top: -30% !important;
}
    .d-flex.flipSelection .flip-block {
    padding: 0 15px;
    width: calc(50% - 0px) !important;
} */
    .bookWrapper{width:100%;HEIGHT: 470PX;MARGIN-TOP: 200PX;}.innerbody{min-height:250px;}
.feature__list li {flex: 0 0 100%;max-width: 100%;} 
.feature__single {padding: 15px 20px 0px;margin-bottom:10px;}
.section-title {font-size: 26px;line-height: 37px;margin-top: 5px;}
.tp-counter-content p {font-size: 13px;letter-spacing: 0px;}
.about-sec__img-two {display: none;}
.about-sec__img {margin-bottom: 50px;}
section.feature {padding-top: 40px;}
.about-sec {padding:40px 0 40px;}
.event-sec{padding:40px 0 40px;}
.lcsd-choose-wrap{padding:40px 0 40px;}
.bg-silver-light{padding:40px 0 40px;}
.project-area{padding:40px 0 40px;}
.why-choose-three__left{padding:40px 0 40px;}
.why-choose-three__img {clip-path: none;}
.why-choose-three__right {margin-left: 0;margin-right: 0;margin-top: 50px;}
.why-choose-three__left:before {transform: skewX(0deg);}
.why-choose-three-shape-1, .why-choose-three-shape-2, .why-choose-three-shape-3 { display: none;}
.newsletter-contact {justify-content: center;}
.newsletter-content .title {text-align: center;font-size: 24px;line-height: 40px;margin-bottom: 31px;}
.footer-bg {background-position: left;    background: #022939;}
.copyright-text p {line-height: 23px;}
.innerbody_left {margin-right: 0;margin-bottom: 119px;}
.innerbody__right {margin-left: 0;}.flexbox-v > div {width: 46%;}
.sub-gallery > div {width: 45%;margin-right: 10px;margin-bottom: 10px;}
.yyy {width: 50%;}
.Council_mo table {width: 850px!important;}
.Council_mo {overflow: auto;}
.thm-breadcrumb li a {color: #000000;text-shadow: 0px -1px 12px #000000;}
.slider-rightmenu li.group {margin: 48px -70px 35px 0px;}
.slider-rightmenu {top: 515px;}


}

@media (max-width: 767px)    
{
    .logo img {
        width: 100%;
    }
    .erpboximg {
        width: 100%;
    }
    .erpboxdesc {
        width: 100%;
    }
    .newnews:after {
        background: linear-gradient(304deg, #a51e1e00 45%, #f3c143 35%);
    }
    .kinder {
        background-size: 40% 50%;
    }
    .event-cal {
        width: 70%;
        margin: 0 auto 30px auto;
    }
    .activitesbox {
        width: 100%;
    }
    .container-fluid.activitesfulwdh {
        padding: 0px 15px;
    }
    .actititle {
        width: 100%;
        margin-left: 0;
        text-align: center;
        margin-bottom: 30px;
    }
    .activitiesslide {
        width: 100%;
    }
    .activitesbg {
        padding: 20px;
        flex-wrap: wrap;
        border-bottom: 0px solid #edcb0b;
        border-top: 0px solid #edcb0b;
        border-right: 0px solid #edcb0b;
    }
    .activitestitle p {
        text-align: center;
    }
    .activitesbg:after {
        border-left: 0px solid #edcb0b;
    }
    .activitesbg:before {
        border-left: 0px solid #edcb0b;
    }
    .container-fluid.activitesfulwdh {
        padding: 0px 15px;
    }
    .actititle {
        width: 100%;
        margin-left: 0;
    }
    .activitiesslide {
        width: 100%;
    }
    .activitesbg {
        flex-wrap: wrap;
    }
    .slider-rightmenu ul.no-bullets li a {
    font-size: 12px;
    font-weight: normal;
    padding: 10px;
    margin: 2px;
}
.slider-rightmenu li.group {
    margin: 48px -40px 35px 0px;
}
.slider-rightmenu {
    top: 450px;
}
    .Council_mo td img {
    height: 100px;
    min-width: 100px;
}
    .Council_mo td {
    min-width: 230px;
    vertical-align: middle;
}
    .align-images img{
      height: auto;

    }
.newsletter-content .title {
    font-size: 20px;
    line-height: 30px;
}
#exampleModalToggle .modal-dialog {
    min-width: 100%;
}
     .react-transform-component.transform-component-module_content__FBWxo {
        top: 10% !important;
        position: relative;
    }
    .flipPageComponent.stf__parent {
       margin-top: -11% !important;
    }
    .flipOpen { 
       width: 500px !important;
    }
    /*.flip-popup>.flipOpen {
    max-height: 480px;
    overflow: hidden;
}
    .flipPageComponent.stf__parent {
    transform: scale(1.1);
}*/
.online-reg{bottom:10px;}.bookWrapper{height: 350PX;}.book-search img{display:none;}.book-search input{width:180px;}.head_girl{float:none;margin:auto;margin-bottom:20px;display:block;border: none;}

.contact-page p {line-height: 30px;text-align: inherit;display: inherit;}
.condetails {justify-content: space-between;}
.about-sec__arrow {display: none;}
.section-title {font-size: 22px;line-height: 31px;}  
.text-btn {padding-bottom:10px;padding-top:10px;} 
.head2 {font-size: 17px;}
.modal-dialog {width: 100%;padding: 15px; margin: 0px auto;}
.lcsd-section-text {margin-right: 0px;}
.about-sec__call {left: 0px;}
.feature__icon {height: 70px;width: 70px;}
.lcsd-choose-contain-qoute {padding: 15px;}
.why-choose-three{/* overflow-x: hidden; */}
.slider-rightmenu{overflow-x: hidden;}
.menu-p-0 {padding: 0px 15px;}
.page-header-bg { width: 100%;}
.innerbody_left__img-two { display: none;}
.innerbody_left {margin-right: 0;margin-bottom: 40px;}
.page-header__inner h2 {font-size: 25px;line-height: 40px;}
.page-header {padding:70px 0 70px;}
.flexbox-v > div { width: 100%;   margin-left: 0;}
.sub-gallery > div { width: 100%; background: #ddd; margin-right: unset; margin-bottom: 10px; }
 .tc {width: 100%;}.tcblock iframe { width: 100%; }
.wt-team-1 {float: inherit;padding-left: 0px;width: 100%;}
.head1 {font-size: 20px;margin-bottom: 10px;}
.yyy {width:100%;}
.achievementimg.achievementmulti a {width: 100%;}
.achievementimg img {height:auto;}
    .maximspic>div {
    width: 46%;
        margin-bottom: 10px;
        margin-left: 10px;
}
.maximspic > div img {
    min-height: 165px;
    max-height: 165px;
}
}

@media (max-width: 575.98px) 
{
    .kinder .project-item {
        width: 100%;
        margin-top: 87px;
        margin-left: 0;
    }
    .event-cal {
        width: 100%;
    }
    .newnews:after {
        background: linear-gradient(278deg, #a51e1e00 45%, #f3c143 35%);
    }
    .maximspic>div {
    width: 100%;
        margin-left: 0;
    }
    .maximspic > div img {
    min-height: 100%;
    max-height: 100%;
}
    .about-sec__left {
    margin: 0 auto 0px auto;
}
    .slider-rightmenu {
    position: fixed;
    }
    .flipPageComponent.stf__parent {
    margin-top: -1% !important; 
    min-width: 100% !important;
}
    .stf__item{
        width: 100% !important;
        left: 50% !important;
        transform: translate(-50%, 0%);
    }

    /* .d-flex.flipSelection .flip-block {
    padding: 0 15px;
    width: calc(100% - 0px) !important;
}
     .d-flex.flipSelection .flip-block{
        margin-bottom: 40px;
    } */
    .bookWrapper{height:320PX;margin-top: 50px;}
.lcsd-choose-img {width: 300px;height: 300px;}
.footer-top {padding: 100px 0 25px;}
.feature__title {font-size: 16px;}
.fw-title .title {margin-bottom: 10px;font-size:18px;}
.erp_links {width: 90px;}
.inr-event-blk {display: grid;}
.inr-event-img {width: 100%;}
.left-align-images {float: none;margin-left: 0px;width: 100%;margin: 0 auto;margin-bottom: 20px;}
.right-align-images {float: none;margin-right: 0px;width: 100%;margin: 0 auto;margin-bottom: 20px;}
.flipOpen {
    width: 90% !important;
    transform: scale(1);
    margin: auto;
}
}
@media(max-width: 500px){
    .midsecbox1 {
        width: 50%;
        flex-wrap: wrap;
    }
    .kinder .project-item {
    width: 100%;
    margin: auto;
}
    .kinder { 
        background: unset;
    padding: 0px 0px 0px; 
}
   
     /*.flipPageComponent.stf__parent {
    transform: scale(0.8);
}
     .flip-popup>.flipOpen .flipPageComponent.stf__parent {
    margin: auto;
    min-width: 120px !important;
}*/
}
@media (max-width: 420px) 
{
    .book-search {
    justify-content: center;
}
    .book-search .searchBox {
    width: 100%;
    margin-left: 0;
}
.book-search input{width: 100%;}
.book-search .month-selection {
    width: 100%;
}
    .book-search {
    justify-content: flex-start;
    flex-wrap: wrap;
}
    .innerbody {
    padding-top: 40px;
}
    .section-title {
    font-size: 20px;
    line-height: 31px;
}
.about-sec__btn-box {
    flex-wrap: wrap;
}
.about-sec a {
    margin-bottom: 15px;
}
.newsletter-inner {
    padding: 35px 10px 35px;
}
}
@media (max-width: 400px) {
    .flipPageComponent.stf__parent {
    margin-top: -1% !important; 
    min-width: 300px !important
}
    .bookWrapper{height:240px;}}
    


@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }